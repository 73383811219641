import { useLinkTo } from '@react-navigation/native'
import { useBreakpointValue } from 'native-base'
import { useProfile } from 'organisms/Profile/useProfile'

import { UseProfileScreen } from './ProfileScreen.types'

export const useProfileScreen: UseProfileScreen = ({ route }) => {
  const { params } = route

  const { profile, handleLogout } = useProfile()

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const linkTo = useLinkTo()

  const handleGoToAddresses = () => linkTo('/enderecos')

  return {
    isMobile,
    slug: params?.slug ?? '',
    profile,
    handleGoToAddresses,
    handleLogout
  }
}
