import { FunctionComponent } from 'react'

import { useTheme } from 'native-base'
import Animated, {
  Extrapolation,
  interpolate,
  interpolateColor,
  SharedValue,
  useAnimatedStyle
} from 'react-native-reanimated'

type AnimatedSlideDotProps = {
  index: number
  scrollX: SharedValue<number>
  slideWidth: number
}

const AnimatedBox = Animated.View

export const AnimatedSlideDot: FunctionComponent<AnimatedSlideDotProps> = ({
  index,
  scrollX,
  slideWidth
}) => {
  const theme = useTheme()

  const animatedStyle = useAnimatedStyle(() => ({
    backgroundColor: interpolateColor(
      scrollX.value,
      [(index - 1) * slideWidth, index * slideWidth, (index + 1) * slideWidth],
      [theme.colors.gray[300], theme.colors.primary[500], theme.colors.gray[300]]
    ),
    height: interpolate(
      scrollX.value,
      [(index - 1) * slideWidth, index * slideWidth, (index + 1) * slideWidth],
      [6, 4, 6],
      Extrapolation.CLAMP
    ),
    width: interpolate(
      scrollX.value,
      [(index - 1) * slideWidth, index * slideWidth, (index + 1) * slideWidth],
      [6, 32, 6],
      Extrapolation.CLAMP
    ),
    borderRadius: 50
  }))

  return <AnimatedBox style={animatedStyle} />
}
