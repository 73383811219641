import { FunctionComponent } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Icons, Illustrations } from 'atoms'
import { Box, Button, Heading, Text, VStack } from 'native-base'

export const AddressEmpty: FunctionComponent = () => {
  const linkTo = useLinkTo()

  return (
    <VStack>
      <Heading
        color="gray.700"
        fontWeight={700}
        fontSize={{ base: '18px', lg: '24px' }}
        lineHeight={{ base: '24px', lg: '36px' }}
        mb={8}>
        Meus endereços
      </Heading>
      <VStack margin="auto" bg="white" justifyContent="center" space={6}>
        <Box margin="auto">
          <Illustrations.NoEmail />
        </Box>

        <Text
          fontSize={{ base: '16px', lg: '20px' }}
          maxW={{ base: '85%', lg: '' }}
          textAlign={{ base: 'center', lg: 'initial' }}
          lineHeight={{ base: '24px', lg: '30px' }}
          color="gray.500"
          margin="auto">
          Você não possui endereços cadastrados.
        </Text>

        <Button
          onPress={() => linkTo(`/endereco/salvar/`)}
          margin="auto"
          size="md"
          flex={1}
          variant="solid"
          maxW="194px"
          _text={{
            fontSize: '16px',
            fontWeight: 'semibold',
            lineHeight: '21px'
          }}
          startIcon={<Icons.Add h={4} w={4} color="white" />}>
          Adicionar endereço
        </Button>
      </VStack>
    </VStack>
  )
}
