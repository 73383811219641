import { useState } from 'react'

import { BackButton, Breadcrumb, Icons } from 'atoms'
import { Rating } from 'molecules'
import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  HStack,
  ScrollView,
  StatusBar,
  Text,
  theme,
  useBreakpointValue,
  View,
  VStack
} from 'native-base'
import {
  FooterDesktop,
  HeaderDesktop,
  ProductGalleryMobile,
  ProductGalleryDesktop,
  ProductSelect,
  RatingList
} from 'organisms'
import { Dimensions } from 'react-native'
import { RootStackScreenComponent } from 'src/navigation'

import { useProductScreen } from './useProductScreen'

const { width: SCREEN_WIDTH } = Dimensions.get('window')

export const RIGHT_BANNER_WIDTH = 493

export const SLIDE_WIDTH =
  SCREEN_WIDTH - (SCREEN_WIDTH >= theme.sizes.container.lg ? RIGHT_BANNER_WIDTH : 0)

export const ProductScreen: RootStackScreenComponent<'ProductDetails'> = ({
  navigation,
  route
}) => {
  const [colorSelected, setColorSelected] = useState<string | undefined>()

  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const { params } = route

  const { product } = useProductScreen(params?.idOrSlug ?? '')

  if (!product) {
    return <View />
  }

  const images = colorSelected
    ? product.images.filter((image) => image.color === colorSelected)
    : product.images

  const mainImages = images?.filter((image) => image.main) ?? []

  const [mainImage] = mainImages?.length > 0 ? mainImages : images ? images : []

  return (
    <Box flex={1} safeAreaTop>
      <ScrollView>
        <HeaderDesktop />

        {isDesktop ? (
          <VStack alignItems="center" flex={1} mb={30}>
            <Container>
              <HStack w="full" alignItems="center" justifyContent="space-between" mt={4}>
                <BackButton linkTo="/inicio">Voltar para Home</BackButton>

                <Breadcrumb>
                  <Breadcrumb.Link linkTo="/inicio">Home</Breadcrumb.Link>
                  <Breadcrumb.Link linkTo="/produtos/todos">Produtos</Breadcrumb.Link>
                  <Breadcrumb.Item>{product.name}</Breadcrumb.Item>
                </Breadcrumb>
              </HStack>

              <Heading mt="95px">Detalhes do produto</Heading>

              <HStack w="full" maxW="1280px" justifyContent="space-between">
                <ProductGalleryDesktop
                  images={images}
                  name={product.name}
                  description={product.description}
                  mainImage={mainImage}
                />

                <VStack width="full" maxW="600px" mt={10}>
                  <ProductSelect
                    productSlug={product.slug}
                    productId={product.id}
                    name={product.name}
                    description={product.description}
                    price={product.price}
                    favorite={product.favorite}
                    sizeTableImage={product.sizeTableImage}
                    colorsWithSizes={product.colorsWithSizes}
                    productImage={mainImage}
                    onSelectColor={setColorSelected}
                  />
                </VStack>
              </HStack>

              <Heading fontSize={24} mt={15} style={{ fontWeight: '700' }}>
                Descrição do produto
              </Heading>

              <Text fontSize={18} mt={4} color="gray.700">
                {product.description}
              </Text>

              <Heading fontSize={24} my={4} style={{ fontWeight: '700' }}>
                Avaliações do produto
              </Heading>

              <Center w="full" mb={6}>
                {product.rating.total_rating > 0 ? (
                  <>
                    <VStack space={6}>
                      <VStack space={2}>
                        <Text
                          textAlign="center"
                          fontSize={16}
                          fontWeight={400}
                          lineHeight={24}
                          color="gray.700">
                          Qualidade do produto
                        </Text>
                        <Rating viewScore score={product.rating.average_quality} size={8} />
                      </VStack>
                      <VStack space={2}>
                        <Text
                          textAlign="center"
                          fontSize={16}
                          fontWeight={400}
                          lineHeight={24}
                          color="gray.700">
                          Conforto
                        </Text>
                        <Rating viewScore score={product.rating.average_confort} size={8} />
                      </VStack>
                      <VStack space={2}>
                        <Text
                          textAlign="center"
                          fontSize={16}
                          fontWeight={400}
                          lineHeight={24}
                          color="gray.700">
                          Tamanho
                        </Text>
                        <Rating viewScore score={product.rating.average_size} size={8} />
                      </VStack>
                    </VStack>
                    <RatingList identifier={product.id} />
                  </>
                ) : (
                  <VStack space={4} alignItems="center">
                    <Text
                      textAlign="center"
                      fontSize={16}
                      fontWeight={400}
                      lineHeight={24}
                      color="gray.700">
                      Esse produto ainda não tem avaliação
                    </Text>
                    <Rating score={0} size={8} />
                  </VStack>
                )}
              </Center>
            </Container>
          </VStack>
        ) : (
          <>
            <StatusBar animated barStyle="light-content" />

            <Button
              variant="ghost"
              position="absolute"
              right={6}
              top={6}
              zIndex={1}
              size={8}
              startIcon={<Icons.Close size={3} color="black" />}
              onPress={navigation.goBack}
            />

            <ProductGalleryMobile
              images={images}
              name={product.name}
              description={product.description}
            />

            <ProductSelect
              productSlug={product.slug}
              productId={product.id}
              name={product.name}
              description={product.description}
              price={product.price}
              favorite={product.favorite}
              sizeTableImage={product.sizeTableImage}
              colorsWithSizes={product.colorsWithSizes}
              productImage={mainImage}
              rating={product.rating}
              onSelectColor={setColorSelected}
            />
          </>
        )}
        <FooterDesktop />
      </ScrollView>
    </Box>
  )
}
