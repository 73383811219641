import { FunctionComponent } from 'react'

import { Text } from 'native-base'

import config from '../../../../app.config'

export type VersionProps = {
  color?: string
}

export const Version: FunctionComponent<VersionProps> = ({ color = 'gray.700' }) => {
  return (
    <Text fontSize="14px" lineHeight="22px" fontWeight={400} mt={9} mb={4} color={color}>
      Lojinha
      <Text color={color} fontWeight="bold">
        {' '}
        Financeira v.{config.version} 2025
      </Text>
    </Text>
  )
}
