import { FunctionComponent } from 'react'

import { Center, Heading, Text } from 'native-base'

type EmptyStateProps = {
  description: string
  title: string
}

export const EmptyState: FunctionComponent<EmptyStateProps> = ({ description, title }) => {
  return (
    <Center flex={1}>
      <Heading>{title}</Heading>
      <Text>{description}</Text>
    </Center>
  )
}
