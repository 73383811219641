import { FunctionComponent, useMemo } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Icons } from 'atoms'
import { useGetAddressQuery } from 'integration/resources/address'
import { AddressCard, AddressEmpty } from 'molecules'
import {
  Button,
  FlatList,
  Heading,
  HStack,
  Skeleton,
  useBreakpointValue,
  VStack
} from 'native-base'

const LIMIT_ADDRESS = 5

export type AddressListProps = {
  isCheckoutFlow: boolean
}

export const AddressList: FunctionComponent<AddressListProps> = ({ isCheckoutFlow }) => {
  const linkTo = useLinkTo()

  const { data, isLoading } = useGetAddressQuery()

  const addresses = useMemo(() => data?.data.results ?? [], [data])

  const mainAddressesFirst = addresses.sort((a, b) => Number(b.main) - Number(a.main))

  const isMobile = useBreakpointValue({ base: true, lg: false })

  return (
    <>
      {isLoading ? (
        <>
          {[0, 1, 2].map((_, index) => (
            <VStack space={2} w="full" mb={6} key={index}>
              <HStack
                bg="white"
                borderRadius="10px"
                px={4}
                py={6}
                space={2}
                style={{
                  shadowColor: 'rgba(0, 0, 0, 0.2)',
                  shadowOffset: {
                    width: 0,
                    height: 3
                  },
                  shadowOpacity: 0.29,
                  shadowRadius: 4.65,
                  elevation: 7
                }}>
                <VStack space={2} w="full" ml={4}>
                  <Skeleton.Text w={30} lines={1} />
                  <Skeleton.Text w={50} lines={1} />
                  <Skeleton.Text w={15} lines={1} />
                </VStack>
              </HStack>
            </VStack>
          ))}
        </>
      ) : (
        <>
          {addresses.length ? (
            isMobile ? (
              <>
                <HStack display="flex" direction="column" mb={6}>
                  <Heading
                    color="gray.700"
                    fontWeight={700}
                    fontSize="18px"
                    lineHeight="23px"
                    mb={4}>
                    Meus endereços
                  </Heading>
                  <Button
                    onPress={() => linkTo(`/endereco/salvar/`)}
                    size="sm"
                    h="40px"
                    flex={1}
                    variant="outline"
                    borderColor="gray.700"
                    isDisabled={addresses.length > LIMIT_ADDRESS}
                    maxW="194px"
                    _text={{
                      fontSize: '16px',
                      fontWeight: 'semibold',
                      lineHeight: '21px',
                      color: 'gray.700'
                    }}
                    _hover={{
                      borderColor: 'gray.700'
                    }}
                    startIcon={<Icons.Add h={4} w={4} color="gray.700" />}>
                    Adicionar endereço
                  </Button>
                </HStack>
                <HStack mb={11}>
                  <FlatList
                    data={mainAddressesFirst}
                    keyExtractor={(item) => `${item.id}`}
                    contentContainerStyle={{ gap: 24 }}
                    renderItem={({ item }) => {
                      return <AddressCard address={item} isCheckoutFlow={isCheckoutFlow} />
                    }}
                    horizontal={false}
                    showsVerticalScrollIndicator={false}
                  />
                </HStack>
              </>
            ) : (
              <>
                <HStack justifyContent="space-between">
                  <Heading
                    color="gray.700"
                    fontWeight={700}
                    fontSize="24px"
                    lineHeight="36px"
                    mb={8}>
                    Meus endereços
                  </Heading>
                  <Button
                    onPress={() => linkTo(`/endereco/salvar/`)}
                    size="sm"
                    flex={1}
                    variant="outline"
                    borderColor="gray.700"
                    isDisabled={addresses.length > LIMIT_ADDRESS}
                    maxW="194px"
                    _text={{
                      fontSize: '16px',
                      fontWeight: 'semibold',
                      lineHeight: '21px',
                      color: 'gray.700'
                    }}
                    _hover={{
                      borderColor: 'gray.700'
                    }}
                    startIcon={<Icons.Add h={4} w={4} color="gray.700" />}>
                    Adicionar endereço
                  </Button>
                </HStack>
                <FlatList
                  data={mainAddressesFirst}
                  keyExtractor={(item) => `${item.id}`}
                  numColumns={2}
                  contentContainerStyle={{ gap: 24 }}
                  columnWrapperStyle={{ gap: 24, paddingLeft: 10, paddingVertical: 10 }}
                  renderItem={({ item }) => {
                    return <AddressCard address={item} isCheckoutFlow={isCheckoutFlow} />
                  }}
                  showsVerticalScrollIndicator={false}
                />
              </>
            )
          ) : (
            <AddressEmpty />
          )}
        </>
      )}
    </>
  )
}
