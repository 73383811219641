import { Icons } from 'atoms'
import { Order } from 'integration/resources/orders/types'
import { Button, Center, Heading, HStack, Skeleton, VStack } from 'native-base'
import { OrdersHistory } from 'organisms'
import { Pressable } from 'react-native'

export type ListPaginationOrdersProps = {
  allDeliveredOrders: Order[]
  isLoading: boolean
  currentPage: number
  totalPages: number
  setCurrentPage: (page: number) => void
}

export const ListPaginationOrders = ({
  allDeliveredOrders,
  isLoading,
  currentPage,
  totalPages,
  setCurrentPage
}: ListPaginationOrdersProps) => {
  const nextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const prevPage = () => {
    setCurrentPage(currentPage - 1)
  }

  const goToPage = (page: number) => {
    setCurrentPage(page)
  }

  const renderSkeleton = () => {
    return [0, 1, 2, 3].map((index) => (
      <HStack
        key={index}
        h="74px"
        justifyContent="space-between"
        alignItems="center"
        space={6}
        borderBottomWidth={1}
        borderColor="gray.100"
        mb={6}>
        <Skeleton size={10} rounded="full" />
        <Skeleton h="3" flex="1" size={10} rounded="full" />
      </HStack>
    ))
  }

  const renderOrders = () => {
    return allDeliveredOrders.map((order) => <OrdersHistory order={order} key={order.id} />)
  }

  const renderPaginationButtons = () => {
    return (
      <Center mt={9}>
        <HStack>
          <Button
            colorScheme="background"
            _pressed={{ bg: 'gray.200' }}
            _hover={{ bg: 'gray.100' }}
            width={12}
            height={12}
            rounded="full"
            onPress={prevPage}
            disabled={currentPage === 1}
            leftIcon={<Icons.Chevron direction="left" color="gray.900" size="sm" />}
          />
          <Center>
            <HStack>
              {[...Array(totalPages)].map((_, index) => (
                <Pressable onPress={() => goToPage(index + 1)} key={index}>
                  <Heading
                    px={13}
                    fontSize="20px"
                    lineHeight="30px"
                    fontWeight={600}
                    color={currentPage === index + 1 ? 'primary.500' : 'gray.900'}>
                    {index + 1}
                  </Heading>
                </Pressable>
              ))}
            </HStack>
          </Center>
          <Button
            colorScheme="background"
            _pressed={{ bg: 'gray.200' }}
            _hover={{ bg: 'gray.100' }}
            width={12}
            height={12}
            rounded="full"
            onPress={nextPage}
            disabled={currentPage === totalPages}
            leftIcon={<Icons.Chevron direction="right" color="gray.900" size="sm" />}
          />
        </HStack>
      </Center>
    )
  }

  return (
    <VStack>
      {isLoading ? renderSkeleton() : renderOrders()}
      {!isLoading && renderPaginationButtons()}
    </VStack>
  )
}
