import { FunctionComponent, useState } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Icons } from 'atoms'
import { Product } from 'integration/resources/products'
import {
  Badge,
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Text,
  useBreakpointValue,
  VStack
} from 'native-base'

import { useFavoriteHandler } from './useFavoriteHandler'

type Props = {
  product: Product
  horizontal?: boolean
}

export const ProductCard: FunctionComponent<Props> = ({
  horizontal,
  product: { image, name, description, price, slug, average_rating, favorite, id }
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const linkTo = useLinkTo()

  const [isHovered, setIsHovered] = useState(false)

  const { handleFavorite } = useFavoriteHandler()

  const toggleFavorite = (slug: string, isFavorite: boolean, product_id: string) => {
    handleFavorite(slug, isFavorite, product_id)
  }

  if (horizontal && !isMobile) {
    return (
      <HStack w="full" space={5}>
        <VStack
          height={64}
          w="full"
          borderRadius={{ base: 0, lg: '10px' }}
          //@ts-ignore
          cursor="pointer"
          onMouseEnter={() => setIsHovered(true)} // Habilita o hover
          onMouseLeave={() => setIsHovered(false)} // Desabilita o hover
        >
          <Box
            style={{
              shadowColor: 'rgba(0, 0, 0, 0.04)',
              shadowOpacity: 1,
              shadowRadius: 16,
              shadowOffset: { width: 0, height: 3 }
            }}>
            <HStack justifyContent="flex-start" textAlign="left" height={55}>
              <VStack w="20%" padding={8}>
                <Image
                  source={
                    image?.imageUrl ? { uri: image.imageUrl } : require('assets/not-found.png')
                  }
                  alt={image?.imageDescription ?? name}
                  borderRadius={{ base: 5, lg: '10px' }}
                  h={{ base: '131px', lg: '131px' }}
                  w={{ base: 'full', lg: '131px' }}
                />
              </VStack>
              <VStack w="80%">
                <HStack top={2} alignItems="flex-start">
                  <VStack flex={1}>
                    <Box>
                      <Heading
                        fontSize={{ base: '16px', lg: '24px' }}
                        lineHeight={{ base: '24px', lg: '36px' }}
                        fontWeight="bold"
                        color={{ base: 'gray.700', lg: 'gray.900' }}
                        mt={4}
                        numberOfLines={1}
                        textAlign="left">
                        {name}
                      </Heading>

                      <Text
                        fontSize={{ base: '16px', lg: '20px' }}
                        lineHeight={{ base: '24px', lg: '30px' }}
                        fontWeight="normal"
                        color="gray.500"
                        mt={2}
                        numberOfLines={1}
                        textAlign="left">
                        {description}
                      </Text>

                      <Heading
                        fontSize={{ base: '16px', lg: '24px' }}
                        lineHeight={{ base: '24px', lg: '36px' }}
                        fontWeight="normal"
                        color={{ base: 'gray.700', lg: 'gray.900' }}
                        mt={4}
                        numberOfLines={1}
                        textAlign="left">
                        {price} Pontos
                      </Heading>

                      {average_rating > 0 && isMobile && (
                        <HStack space={2} mt={2}>
                          <Icons.Star color="tertiary.500" size={4} filled />
                          <Text
                            fontSize="12px"
                            lineHeight="18px"
                            fontWeight="400"
                            color="tertiary.500">
                            {average_rating}
                          </Text>
                        </HStack>
                      )}

                      <HStack space={3} justifyContent="flex-end" flex={1}>
                        {average_rating > 0 && !isMobile && (
                          <HStack space={2} justifyContent="center" alignItems="center">
                            <Icons.Star color="tertiary.500" size={8} filled />
                            <Text
                              fontSize="16px"
                              lineHeight="24px"
                              fontWeight="400"
                              color="tertiary.500">
                              {average_rating}
                            </Text>
                          </HStack>
                        )}

                        <Button
                          variant="ghost"
                          onPress={() => linkTo(`/produto/${slug}`)}
                          borderWidth="1px"
                          borderColor="gray.700"
                          colorScheme="gray"
                          width="100%"
                          maxWidth="246px"
                          marginRight={-8}
                          borderRadius={30}
                          display={{ base: 'none', lg: 'flex' }}>
                          Ver detalhes
                        </Button>
                      </HStack>
                    </Box>
                  </VStack>

                  {isHovered ? (
                    <Button
                      variant="ghost"
                      onPress={() => toggleFavorite(slug, favorite, id)}
                      backgroundColor="background.base"
                      borderRadius={50}
                      w={10}
                      h={10}
                      mr={8}
                      zIndex={1000}>
                      <Icons.Heart color="primary.500" filled={favorite} size={4} />
                    </Button>
                  ) : (
                    <Button variant="ghost" borderRadius={50} w={10} h={10} mr={8} zIndex={1000} />
                  )}
                </HStack>
              </VStack>
            </HStack>
          </Box>
        </VStack>
      </HStack>
    )
  }

  return (
    <VStack
      w={{ base: 39, lg: '341px' }}
      position="relative"
      h={{ base: 'auto', lg: '476px' }}
      borderRadius={{ base: 0, lg: '10px' }}
      //@ts-ignore
      cursor="pointer"
      onMouseEnter={() => setIsHovered(true)} // Habilita o hover
      onMouseLeave={() => setIsHovered(false)} // Desabilita o hover
    >
      <Box
        style={{
          shadowColor: 'rgba(0, 0, 0, 0.04)',
          shadowOpacity: 1,
          shadowRadius: 16,
          shadowOffset: { width: 0, height: 3 }
        }}>
        <Image
          source={image?.imageUrl ? { uri: image.imageUrl } : require('assets/not-found.png')}
          alt={image?.imageDescription ?? name}
          borderRadius={{ base: 5, lg: '10px' }}
          h={{ base: '131px', lg: '304px' }}
          w={{ base: 'full', lg: '341px' }}
          mb={{ lg: '13px' }}
        />
      </Box>

      <HStack px={4} w="full" position="absolute" top={2} justifyContent="space-between">
        <Badge>
          <HStack alignItems="baseline">
            <Heading
              fontSize={{ base: '14px', lg: '18px' }}
              lineHeight={{ base: '18px', lg: '20px' }}
              fontWeight="bold"
              color="white"
              mr="2px">
              {price}
            </Heading>
            <Heading
              fontSize={{ base: '12px', lg: '14px' }}
              lineHeight={{ base: '16px', lg: '18px' }}
              fontWeight="normal"
              color="white"
              textTransform="uppercase">
              pts
            </Heading>
          </HStack>
        </Badge>

        {isHovered && (
          <Button
            variant="ghost"
            onPress={() => handleFavorite(slug, favorite, id)}
            backgroundColor="background.base"
            borderRadius={50}
            w={10}
            h={10}
            zIndex={1000}>
            <Icons.Heart color="primary.500" filled={favorite} size={4} />
          </Button>
        )}
      </HStack>

      <VStack flex={1} justifyContent="space-between">
        <Heading
          fontSize={{ base: '16px', lg: '24px' }}
          lineHeight={{ base: '24px', lg: '36px' }}
          fontWeight="bold"
          color={{ base: 'gray.700', lg: 'gray.900' }}
          mt={4}
          numberOfLines={1}
          textAlign={{ base: 'left', lg: 'center' }}>
          {name}
        </Heading>

        <Text
          fontSize={{ base: '12px', lg: '14px' }}
          lineHeight={{ base: '16px', lg: '18px' }}
          fontWeight="normal"
          color="gray.500"
          mt={2}
          numberOfLines={3}
          textAlign={{ base: 'left', lg: 'center' }}>
          {description}
        </Text>

        {average_rating > 0 && isMobile && (
          <HStack space={2} mt={2}>
            <Icons.Star color="tertiary.500" size={4} filled />
            <Text fontSize="12px" lineHeight="18px" fontWeight="400" color="tertiary.500">
              {average_rating}
            </Text>
          </HStack>
        )}

        <HStack space={3} mt={6} justifyContent="center" flex={1}>
          {average_rating > 0 && !isMobile && (
            <HStack space={2} justifyContent="center" alignItems="center">
              <Icons.Star color="tertiary.500" size={8} filled />
              <Text fontSize="16px" lineHeight="24px" fontWeight="400" color="tertiary.500">
                {average_rating}
              </Text>
            </HStack>
          )}

          <Button
            variant="ghost"
            onPress={() => linkTo(`/produto/${slug}`)}
            borderWidth="1px"
            borderColor="gray.700"
            colorScheme="gray"
            width="100%"
            maxWidth="246px"
            borderRadius={30}
            display={{ base: 'none', lg: 'flex' }}>
            Ver detalhes
          </Button>
        </HStack>
      </VStack>
    </VStack>
  )
}
