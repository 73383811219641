import { useDeviceLogoutMutation } from 'integration/resources/auth'
import { ProfileDataSerialized, useGetProfileQuery } from 'integration/resources/profile'
import { useBreakpointValue } from 'native-base'
import { useQueryClient } from 'react-query'
import { useSetAuthAtom } from 'src/store/auth'
import { registerForPushNotificationsAsync } from 'src/utils/device'

export type UseProfile = {
  isMobile: boolean
  isLoading: boolean
  profile?: ProfileDataSerialized
  handleLogout(): Promise<void>
}

export const useProfile = (): UseProfile => {
  const isMobile = useBreakpointValue({ base: true, lg: false }) || false

  const { data: profile, isLoading } = useGetProfileQuery()

  const queryClient = useQueryClient()

  const setAuthAtom = useSetAuthAtom()

  const { mutate } = useDeviceLogoutMutation()

  const handleLogout = async () => {
    queryClient.invalidateQueries()

    const expoPushToken = (await registerForPushNotificationsAsync()) ?? ''

    mutate(
      { expo_push_token: expoPushToken },
      {
        onError: () => setAuthAtom(null),
        onSuccess: () => setAuthAtom(null)
      }
    )
  }

  return {
    isMobile,
    isLoading,
    profile: profile?.data,

    handleLogout
  }
}
