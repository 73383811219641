import { FunctionComponent, useMemo } from 'react'

import { Box, Heading, Image, Text, useBreakpointValue } from 'native-base'
import Animated, {
  Extrapolation,
  interpolate,
  SharedValue,
  useAnimatedStyle
} from 'react-native-reanimated'
import { Gender, Provider } from 'src/integration/resources/onboarding'

import { GenderSelector } from './GenderSelector'
import { ProviderSelector } from './ProviderSelector'
import { DESKTOP_SLIDE_CONTAINER_WIDTH, Slide, slides, SLIDE_WIDTH } from '../useOnboardingScreen'

type AnimatedSlideProps = {
  index: number
  onSelectGender(gender: Gender): void
  onSelectProvider(provider: Provider): void
  scrollX: SharedValue<number>
  slide: Slide
  userIsConsultant?: boolean
}

const AnimatedBox = Animated.View

export const AnimatedSlide: FunctionComponent<AnimatedSlideProps> = ({
  index,
  onSelectGender,
  onSelectProvider,
  scrollX,
  slide,
  userIsConsultant
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const isLast = useMemo(() => index === slides.length - 1, [index])

  const isNextToLast = useMemo(() => index === slides.length - 2, [index])

  const animatedImageStyle = useAnimatedStyle(() => ({
    opacity: interpolate(
      scrollX.value,
      [(index - 1) * SLIDE_WIDTH, index * SLIDE_WIDTH, (index + 1) * SLIDE_WIDTH],
      [0, 1, 0],
      Extrapolation.CLAMP
    ),
    transform: [
      // @TODO temporarly, because in new version not working correctly type
      // @ts-ignore
      {
        scale: interpolate(
          scrollX.value,
          [(index - 1) * SLIDE_WIDTH, index * SLIDE_WIDTH, (index + 1) * SLIDE_WIDTH],
          [0.5, 1, 0.5],
          Extrapolation.CLAMP
        )
      }
    ]
  }))

  const animatedTitleStyle = useAnimatedStyle(() => ({
    opacity: interpolate(
      scrollX.value,
      [(index - 1) * SLIDE_WIDTH, index * SLIDE_WIDTH, (index + 1) * SLIDE_WIDTH],
      [0, 1, 0],
      Extrapolation.CLAMP
    ),
    transform: [
      // @TODO temporarly, because in new version not working correctly type
      // @ts-ignore
      {
        translateX: interpolate(
          scrollX.value,
          [(index - 1) * SLIDE_WIDTH, index * SLIDE_WIDTH, (index + 1) * SLIDE_WIDTH],
          [-64, 1, 64],
          Extrapolation.CLAMP
        )
      }
    ]
  }))

  const animatedContentStyle = useAnimatedStyle(() => ({
    opacity: interpolate(
      scrollX.value,
      [(index - 1) * SLIDE_WIDTH, index * SLIDE_WIDTH, (index + 1) * SLIDE_WIDTH],
      [0, 1, 0],
      Extrapolation.CLAMP
    ),
    transform: [
      // @TODO temporarly, because in new version not working correctly type
      // @ts-ignore
      {
        translateX: interpolate(
          scrollX.value,
          [(index - 1) * SLIDE_WIDTH, index * SLIDE_WIDTH, (index + 1) * SLIDE_WIDTH],
          [-32, 1, 32],
          Extrapolation.CLAMP
        )
      }
    ]
  }))

  return (
    <Box
      alignItems={{ lg: 'center' }}
      flex={1}
      px={{ base: 6, lg: 0 }}
      w={`${SLIDE_WIDTH}px`}
      justifyContent="space-between">
      <Box w={{ lg: DESKTOP_SLIDE_CONTAINER_WIDTH }} h="full">
        {isMobile && (
          <AnimatedBox style={animatedImageStyle}>
            <Image
              alt={slide.title}
              h={100}
              maxH="426px"
              source={slide.image}
              resizeMode="contain"
              w="full"
            />
          </AnimatedBox>
        )}

        <Box>
          <AnimatedBox style={animatedTitleStyle}>
            <Heading
              fontSize={{ base: '28px', lg: '32px' }}
              fontWeight={isMobile ? 'extrabold' : 'bold'}
              lineHeight={{ base: '36px', lg: '44px' }}
              mb={6}
              mt={{ base: 10, lg: 0 }}>
              {slide.title}
            </Heading>
          </AnimatedBox>
          <AnimatedBox style={animatedContentStyle}>
            {isLast ? (
              <GenderSelector onSelect={onSelectGender} />
            ) : isNextToLast ? (
              <ProviderSelector onSelect={onSelectProvider} userIsConsultant={userIsConsultant} />
            ) : (
              <Text
                color="gray.700"
                fontSize={{ base: '16px', lg: '18px' }}
                lineHeight={{ base: '24px', lg: '32px' }}>
                {slide.description}
              </Text>
            )}
          </AnimatedBox>
        </Box>
      </Box>
    </Box>
  )
}
