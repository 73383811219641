import { FunctionComponent, lazy } from 'react'

import { Icons } from 'atoms'
import { TrackOrderType } from 'integration/resources/track-order'
import { Box, Center, Flex, HStack, Heading, Image, Text, VStack } from 'native-base'
import { ORDER_UNPRODUCTIVE } from 'src/constants/order'
import { normalizeDate } from 'src/utils/normalizeDates'

export type TrackOrderDetailProps = {
  trackOrder: TrackOrderType
  isMobile: boolean
}

const TrackOrderStepperDesktop = lazy(() => import('./TrackOrderStepper.desktop'))

const TrackOrderStepperMobile = lazy(() => import('./TrackOrderStepper.mobile'))

export const TrackOrderDetail: FunctionComponent<TrackOrderDetailProps> = ({
  trackOrder,
  isMobile
}) => {
  const TrackOrderStepper = isMobile ? TrackOrderStepperMobile : TrackOrderStepperDesktop

  const {
    order_products,
    address,
    user_responsible,
    delivery_forecast,
    error,
    order_id,
    steps,
    status
  } = trackOrder

  return (
    <VStack space={2}>
      <HStack justifyContent="space-between" alignItems="center">
        <Heading
          fontSize={{ base: '14px', lg: '20px' }}
          lineHeight={{ base: '18px', lg: '30px' }}
          color={{ base: 'gray.500', lg: 'gray.700' }}>
          Pedido N.#{order_id}
        </Heading>

        <HStack space={2} alignItems="center">
          {error.message && (
            <>
              <Text
                fontSize={{ base: '12px', lg: '16px' }}
                lineHeight={{ base: '18px', lg: '24px' }}
                color="gray.500">
                {status.includes(ORDER_UNPRODUCTIVE) ? 'Entrega Improdutiva' : 'Pedido Cancelado'}
              </Text>
              <Box bgColor="#454954" borderRadius={8} p={1}>
                <Icons.Close color="#fff" size={2} />
              </Box>
            </>
          )}
        </HStack>
      </HStack>

      <VStack space={8}>
        {order_products.map((product, index) => (
          <VStack space={8} key={product.product_id}>
            <HStack alignItems="center" space={4} key={index}>
              <Image
                w={{ base: '48px', lg: '64px' }}
                h={{ base: '48px', lg: '64px' }}
                alt={product.name}
                source={product.image ? { uri: product.image } : require('assets/not-found.png')}
                borderRadius="10px"
              />

              {isMobile ? (
                <Flex alignItems="flex-start" ml={4}>
                  <HStack space={2} mb="5px">
                    <Center
                      w="17px"
                      h="17px"
                      borderRadius="full"
                      backgroundColor="gray.300"
                      pl="1px">
                      <Heading fontSize="10px" lineHeight="13px" fontWeight="bold" color="white">
                        {product.amount}
                      </Heading>
                    </Center>

                    <Text fontSize="12px" maxW="85%" lineHeight="16px" color="gray.800">
                      {product.name}
                    </Text>
                  </HStack>

                  <Text fontSize="12px" lineHeight="18px" color="gray.500" mb="4px">
                    Tamanho: {product.size}/ Cor: {product.color}
                  </Text>

                  <Text fontSize="12px" lineHeight="18px" color="gray.700">
                    {product.price} Pontos
                  </Text>
                </Flex>
              ) : (
                <HStack space={4} alignItems="center">
                  <Center w="20px" h="20px" borderRadius="full" backgroundColor="gray.300" pl="1px">
                    <Heading fontSize="10px" lineHeight="13px" fontWeight="bold" color="white">
                      {product.amount}
                    </Heading>
                  </Center>

                  <Text fontSize="20px" lineHeight="30px" color="gray.900" fontWeight="bold">
                    {product.name}
                  </Text>

                  <Box borderRightWidth="1px" borderColor="gray.500" h="18px" />

                  <Text fontSize="20px" lineHeight="30px" color="gray.500">
                    Tamanho: {product.size}/ Cor: {product.color}
                  </Text>

                  <Box borderRightWidth="1px" borderColor="gray.500" h="18px" />

                  <Text fontSize="20px" lineHeight="30px" color="gray.700">
                    {product.price} Pontos
                  </Text>
                </HStack>
              )}
            </HStack>
          </VStack>
        ))}

        <VStack>
          {Object.keys(address).length ? (
            <HStack space={2} alignItems="center">
              <Icons.Truck size={4} color="primary.500" mt="2px" />
              <Text
                fontSize={{ base: '12px', lg: '16px' }}
                lineHeight={{ base: '18px', lg: '24px' }}
                color={{ base: 'gray.500', lg: 'gray.700' }}>
                {address.street}, {address.number},{' '}
                {address.complement ? `, ${address.complement}, ` : ''} {address.postal_code} -{' '}
                {address.state} - {address.city}
              </Text>
            </HStack>
          ) : (
            <></>
          )}

          {user_responsible && (
            <Text
              fontSize={{ base: '12px', lg: '16px' }}
              lineHeight={{ base: '18px', lg: '24px' }}
              color={{ base: 'gray.500', lg: 'gray.700' }}>
              Responsável: {user_responsible}.
            </Text>
          )}
        </VStack>

        <HStack>
          {!isMobile && (
            <VStack w="30%" space={2}>
              <Text
                fontSize={{ base: '12px', lg: '16px' }}
                lineHeight={{ base: '18px', lg: '24px' }}
                color="gray.800"
                fontWeight={500}>
                Previsão de entrega
              </Text>
              <Text
                fontSize={{ base: '12px', lg: '16px' }}
                lineHeight={{ base: '18px', lg: '24px' }}
                color="gray.800"
                fontWeight={400}>
                {normalizeDate(delivery_forecast, "dd 'de' MMMM',' yyyy")}
              </Text>
            </VStack>
          )}
          {!error.message && (
            <VStack w={isMobile ? '100%' : '70%'}>
              <TrackOrderStepper steps={steps} />
            </VStack>
          )}
        </HStack>
        {error.message && (
          <HStack space={2}>
            <Box>
              <Icons.InformationCicle size={4} color="white" mt={1} />
            </Box>

            <VStack maxW="100%">
              <Text
                fontSize={{ base: '12px', lg: '16px' }}
                lineHeight={{ base: '18px', lg: '24px' }}
                color="gray.700"
                fontWeight={400}>
                {error.message}
              </Text>
              {error.reason && (
                <Text
                  fontSize={{ base: '12px', lg: '16px' }}
                  lineHeight={{ base: '18px', lg: '24px' }}
                  color="gray.500"
                  fontWeight={400}>
                  Motivo: {error.reason}
                </Text>
              )}
            </VStack>
          </HStack>
        )}

        {isMobile && (
          <HStack justifyContent="space-between">
            <Text
              fontSize={{ base: '12px', lg: '16px' }}
              lineHeight={{ base: '18px', lg: '24px' }}
              color="gray.500"
              fontWeight={500}>
              Previsão de entrega
            </Text>
            <Text
              fontSize={{ base: '12px', lg: '16px' }}
              lineHeight={{ base: '18px', lg: '24px' }}
              color="gray.500"
              fontWeight={400}>
              {normalizeDate(delivery_forecast, "dd 'de' MMMM',' yyyy")}
            </Text>
          </HStack>
        )}
      </VStack>
    </VStack>
  )
}
