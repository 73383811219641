import { FunctionComponent } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Icons } from 'atoms'
import { ProductOrder, Address, OrderStatus } from 'integration/resources/orders/types'
import {
  Box,
  Center,
  Flex,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
  useBreakpointValue,
  Button
} from 'native-base'
import { ORDER_DELIVERED, ORDER_NOT_FINISHED, ORDER_PROGRESS } from 'src/constants/order'

type Props = {
  products: ProductOrder[]
  location: Address
  orderId: string
  status: OrderStatus
  reviewed: boolean
  confirmed_at?: string
  receivedOrder?: () => void
  cancelOrder?: () => void
  reviewedOrder?: () => void
}

export const OrdersDetails: FunctionComponent<Props> = ({
  products,
  location,
  orderId,
  status,
  reviewed,
  confirmed_at,
  receivedOrder,
  cancelOrder,
  reviewedOrder
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const _linkTo = useLinkTo()

  const cancelOrderButton = [ORDER_PROGRESS, ORDER_NOT_FINISHED].includes(status)

  const revieweOrderButton = confirmed_at && reviewed

  const receivedOrderBuutton = status === ORDER_DELIVERED && receivedOrder && !confirmed_at

  return (
    <VStack
      textAlign="center"
      bg="white"
      borderColor="gray.100"
      borderTopWidth="1px"
      pt={4}
      mb={{ base: 2, lg: 0 }}
      space={4}
      w="full">
      {(products ?? []).map((product, index) => (
        <HStack alignItems="center" space={4} key={index}>
          <Image
            w={{ base: '48px', lg: '64px' }}
            h={{ base: '48px', lg: '64px' }}
            alt={product.name}
            source={product.image ? { uri: product.image } : require('assets/not-found.png')}
            borderRadius="10px"
          />

          {isMobile ? (
            <Flex alignItems="flex-start" ml={4}>
              <HStack space={2} mb="5px">
                <Center w="17px" h="17px" borderRadius="full" backgroundColor="gray.300" pl="1px">
                  <Heading fontSize="10px" lineHeight="13px" fontWeight="bold" color="white">
                    {product.amount}
                  </Heading>
                </Center>

                <Text fontSize="12px" maxW="85%" lineHeight="16px" color="gray.800">
                  {product.name}
                </Text>
              </HStack>

              <Text fontSize="12px" lineHeight="18px" color="gray.500" mb="4px">
                Tamanho: {product.size}/ Cor: {product.color}
              </Text>

              <Text fontSize="12px" lineHeight="18px" color="gray.500">
                Valor: {product.price} pts
              </Text>
            </Flex>
          ) : (
            <VStack space={4} alignItems="flex-start">
              <HStack space={4} alignItems="center">
                <Center w="20px" h="20px" borderRadius="full" backgroundColor="gray.300" pl="1px">
                  <Heading fontSize="10px" lineHeight="13px" fontWeight="bold" color="white">
                    {product.amount}
                  </Heading>
                </Center>

                <Text fontSize="20px" lineHeight="30px" color="gray.900" fontWeight="bold">
                  {product.name}
                </Text>
              </HStack>
              <HStack space={4} alignItems="center">
                <Text fontSize="20px" lineHeight="30px" color="gray.500">
                  Tamanho: {product.size}/ Cor: {product.color}
                </Text>

                <Box borderRightWidth="1px" borderColor="gray.500" h="18px" />

                <Text fontSize="20px" lineHeight="30px" color="gray.500">
                  Valor: {product.price} pts
                </Text>
              </HStack>
            </VStack>
          )}
        </HStack>
      ))}
      <HStack
        alignItems="baseline"
        justifyContent="space-between"
        borderColor="gray.100"
        borderTopWidth="1px"
        pt={4}
        mt={4}
        flexDir={isMobile ? 'column' : 'row'}>
        {Object.keys(location).length ? (
          <HStack alignItems="center" mb={8} space={2} maxW={{ base: '90%', lg: '36%' }}>
            <Icons.Truck size={7} color="primary.500" mt="-2px" />
            <Text
              fontSize={{ base: '12px', lg: '16px' }}
              lineHeight={{ base: '18px', lg: '24px' }}
              color={{ base: 'gray.500', lg: 'gray.700' }}
              textAlign="left">
              {location.street}, {location.number},{' '}
              {location.complement ? location.complement + ',' : ''}CEP {location.postal_code} -{' '}
              {location.district} - {location.city}
            </Text>
          </HStack>
        ) : (
          <Text />
        )}

        <HStack space={4} flexDir={isMobile ? 'column' : 'row'} w={isMobile ? 'full' : 'auto'}>
          {receivedOrderBuutton && (
            <Button
              onPress={receivedOrder}
              width={{ base: 'full', lg: '267px' }}
              mt={isMobile ? 4 : 0}>
              Confirmar recebimento
            </Button>
          )}

          {revieweOrderButton && (
            <Button onPress={reviewedOrder} width={{ base: 'full', lg: '267px' }}>
              Avaliar pedido
            </Button>
          )}

          {!cancelOrder ? (
            <Button
              variant="outline"
              onPress={() => _linkTo(`/acompanhar-pedido/${orderId}`)}
              borderWidth={0}
              endIcon={<Icons.Chevron direction="right" size="4" color="red.500" mt="3px" />}>
              <Heading color="red.500" fontSize="16px" lineHeight="20px" fontWeight={600}>
                Ver detalhes
              </Heading>
            </Button>
          ) : (
            <Button
              onPress={() => _linkTo(`/acompanhar-pedido/${orderId}`)}
              width={{ base: 'full', lg: '267px' }}
              my={isMobile ? 4 : 0}>
              Acompanhar pedido
            </Button>
          )}

          {cancelOrderButton && (
            <Button variant="outline" onPress={cancelOrder} width={{ base: 'full', lg: '250px' }}>
              Cancelar pedido
            </Button>
          )}
        </HStack>
      </HStack>
    </VStack>
  )
}
