import { FunctionComponent } from 'react'

import { Icons } from 'atoms'
import { PointsCard } from 'molecules'
import {
  Box,
  Button,
  HStack,
  Heading,
  Skeleton,
  Text,
  VStack,
  useBreakpointValue
} from 'native-base'

import { useProfile } from './useProfile'

export const Profile: FunctionComponent = () => {
  const { isLoading, profile, handleLogout } = useProfile()

  const isMobile = useBreakpointValue({ base: true, lg: false })

  return (
    <HStack space={{ base: 10, lg: 20 }}>
      {isMobile ? (
        <VStack margin="auto" w="full">
          <Box mb={8}>
            <VStack mb={6}>
              <Skeleton isLoaded={!isLoading} borderRadius="full" maxW="1/6" maxH="24px" mb={2}>
                <Heading fontWeight={700} fontSize="18px" lineHeight="24px" color="gray.700">
                  {profile?.name ?? ''}
                </Heading>
              </Skeleton>
              <Skeleton isLoaded={!isLoading} borderRadius="full" maxW="1/3" maxH="18px">
                <Text fontWeight={400} fontSize="16px" lineHeight="24px" color="gray.500">
                  {profile?.email ?? ''}
                </Text>
              </Skeleton>
            </VStack>

            <Heading fontWeight={600} fontSize="16px" lineHeight="30px" color="gray.500" mb={2}>
              Código Santander
            </Heading>
            <Skeleton isLoaded={!isLoading} borderRadius="full" maxW="1/5" maxH="18px" mb={6}>
              <Text fontWeight={400} fontSize="16px" lineHeight="24px" color="gray.300" mb={6}>
                {profile?.std_code ?? ''}
              </Text>
            </Skeleton>

            <Heading fontWeight={600} fontSize="16px" lineHeight="30px" color="gray.500" mb={2}>
              Segmento
            </Heading>
            <Skeleton isLoaded={!isLoading} borderRadius="full" maxW="1/5" maxH="18px" mb={6}>
              <Text fontWeight={400} fontSize="16px" lineHeight="24px" color="gray.300" mb={6}>
                {profile?.segments?.length &&
                  profile?.segments?.map(
                    (segment, index, size) =>
                      `${segment.segment_id__name}${index + 1 === size.length ? '' : ','} `
                  )}
              </Text>
            </Skeleton>

            <Heading fontWeight={600} fontSize="16px" lineHeight="24px" color="gray.500" mb={2}>
              Sexo
            </Heading>
            <Skeleton isLoaded={!isLoading} borderRadius="full" maxW="1/5" maxH="18px" mb={6}>
              <Text fontWeight={400} fontSize="16px" lineHeight="24px" color="gray.300">
                {profile?.gender === 'M' ? 'Masculino' : 'Feminino'}
              </Text>
            </Skeleton>
          </Box>
          <VStack>
            {profile?.balanceExpirationFormatted && (
              <PointsCard
                expirationDate={profile.balanceExpirationFormatted}
                points={profile.balance}
              />
            )}
            <Button
              onPress={handleLogout}
              variant="ghost"
              _text={{
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '24px',
                color: 'gray.500'
              }}
              _hover={{ bg: 'transparent', _text: { color: 'gray.500' } }}
              _pressed={{ bg: 'transparent', _text: { color: 'gray.500' } }}
              _stack={{
                space: 2
              }}
              endIcon={<Icons.Logout size={3} color="gray.700" />}
              maxW={{ base: '327px', lg: '413px' }}
              maxH={9}
              padding={0}
              justifyContent="flex-start"
              mt={8}
              mb={13}>
              Sair da conta
            </Button>
          </VStack>
        </VStack>
      ) : (
        <>
          <Box>
            <VStack mb={6}>
              <Skeleton isLoaded={!isLoading} borderRadius="full" maxW="1/6" maxH="24px" mb={1}>
                <Heading fontWeight={700} fontSize="24px" lineHeight="36px" color="gray.700">
                  {profile?.name ?? ''}
                </Heading>
              </Skeleton>
              <Skeleton isLoaded={!isLoading} borderRadius="full" maxW="1/3" maxH="18px">
                <Text fontWeight={400} fontSize="18px" lineHeight="29px" color="gray.500">
                  {profile?.email ?? ''}
                </Text>
              </Skeleton>
            </VStack>

            <Heading fontWeight={600} fontSize="20px" lineHeight="30px" color="gray.500" mb={2}>
              Código Santander
            </Heading>
            <Skeleton isLoaded={!isLoading} borderRadius="full" maxW="1/5" maxH="18px" mb={6}>
              <Text fontWeight={400} fontSize="16px" lineHeight="24px" color="gray.300" mb={6}>
                {profile?.std_code ?? ''}
              </Text>
            </Skeleton>

            <Heading fontWeight={600} fontSize="20px" lineHeight="30px" color="gray.500" mb={2}>
              Segmento
            </Heading>
            <Skeleton isLoaded={!isLoading} borderRadius="full" maxW="1/5" maxH="18px" mb={6}>
              <Text fontWeight={400} fontSize="16px" lineHeight="24px" color="gray.300" mb={6}>
                {profile?.segments?.length &&
                  profile?.segments?.map(
                    (segment, index, size) =>
                      `${segment.segment_id__name}${index + 1 === size.length ? '' : ','} `
                  )}
              </Text>
            </Skeleton>

            <Heading fontWeight={600} fontSize="20px" lineHeight="30px" color="gray.500" mb={2}>
              Sexo
            </Heading>
            <Skeleton isLoaded={!isLoading} borderRadius="full" maxW="1/5" maxH="18px" mb={6}>
              <Text fontWeight={400} fontSize="16px" lineHeight="24px" color="gray.300">
                {profile?.gender === 'M' ? 'Masculino' : 'Feminino'}
              </Text>
            </Skeleton>
          </Box>

          <VStack>
            {profile?.balanceExpirationFormatted && (
              <PointsCard
                expirationDate={profile.balanceExpirationFormatted}
                points={profile.balance}
              />
            )}

            <Button
              onPress={handleLogout}
              variant="ghost"
              _text={{
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '24px',
                color: 'primary.500'
              }}
              _hover={{ bg: 'transparent', _text: { color: 'primary.500' } }}
              _pressed={{ bg: 'transparent', _text: { color: 'primary.500' } }}
              _stack={{
                space: 2
              }}
              endIcon={<Icons.Logout size={3} color="primary.500" />}
              maxW={{ base: '327px', lg: '413px' }}
              maxH={9}
              padding={0}
              justifyContent="flex-end"
              mt={8}
              mb={27}>
              Sair da conta
            </Button>
          </VStack>
        </>
      )}
    </HStack>
  )
}
