import { FunctionComponent, useState, useRef } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Icons } from 'atoms'
import { useGetCategoriesQuery } from 'integration/resources/categories'
import { NotificationsButton } from 'molecules'
import {
  Box,
  Center,
  HStack,
  Link,
  Text,
  useBreakpointValue,
  Pressable,
  Heading,
  Image,
  Menu
} from 'native-base'
import { Platform, Animated } from 'react-native'
import { useCheckoutAtomValue } from 'src/store/checkout'

import { navigationProfileList } from './NavigationProfile'

type THeaderDesktopProps = {
  active?: string
}

export const HeaderDesktop: FunctionComponent<THeaderDesktopProps> = ({ active }) => {
  const isVisible = useBreakpointValue({ base: false, lg: true })

  const checkoutAtomString = useCheckoutAtomValue()

  const { data } = useGetCategoriesQuery()

  const categories = data?.data.results

  const linkTo = useLinkTo()

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false)

  const rotateMenuAnim = useRef(new Animated.Value(0)).current

  const rotateProfileMenuAnim = useRef(new Animated.Value(0)).current

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)

    Animated.timing(rotateMenuAnim, {
      toValue: isMenuOpen ? 0 : 1,
      duration: 200,
      useNativeDriver: true
    }).start()
  }

  const toggleProfileMenu = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen)

    Animated.timing(rotateProfileMenuAnim, {
      toValue: isProfileMenuOpen ? 0 : 1,
      duration: 200,
      useNativeDriver: true
    }).start()
  }

  const menuRotation = rotateMenuAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '180deg']
  })

  const profileMenuRotation = rotateProfileMenuAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '180deg']
  })

  const checkoutAtom =
    typeof checkoutAtomString === 'string' ? JSON.parse(checkoutAtomString) : checkoutAtomString

  if (!isVisible) {
    return <></>
  }

  return (
    <Center bg="background.light" w="full">
      <HStack flex={1} w="full" justifyContent="space-between" maxW="1280px" m="auto">
        <HStack>
          <Center flexDirection="row">
            <Link onPress={() => linkTo(`/inicio`)}>
              <Image source={require('assets/logo.png')} alt="Lojinha" w={132} minH={51} />
            </Link>
            <Box w="1px" h={12} mx={10} bgColor="#E6E8EC" />
          </Center>
          {/*
          Disabled temporarily
          <HStack justifyContent="center" alignItems="center" space="md">
            <Input
              placeholder="Buscar produtos..."
              onChangeText={(search) => console.log(search)}
              w="319px"
              h={10}
              py={2}
              pl={5}
              borderWidth="2"
              borderColor="gray.300"
            />
            <Center bg="primary.500" borderRadius={50} w={10} h={10}>
              <Icons.Search size={4} color="white" />
            </Center>
          </HStack> */}
          <Box w="169px">
            <Menu
              w="169px"
              marginTop={-6}
              borderRadius={10}
              bg="background.light"
              borderWidth={0}
              onOpen={toggleMenu}
              onClose={toggleMenu}
              trigger={(triggerProps) => (
                <Pressable {...triggerProps} accessibilityLabel="More options menu">
                  <HStack>
                    <Text
                      color="gray.500"
                      fontWeight={500}
                      fontSize={16}
                      lineHeight={24}
                      pl={10}
                      pr={2}
                      py={6}>
                      Categorias
                    </Text>
                    <Animated.View style={{ transform: [{ rotate: menuRotation }] }}>
                      <Icons.Arrow color="gray.500" direction="down" size={6} my={6} />
                    </Animated.View>
                  </HStack>
                </Pressable>
              )}>
              {categories?.map((category) => (
                <Menu.Item
                  key={category.id}
                  onPress={() => linkTo(`/produtos/${category.slug}`)}
                  alignItems="center"
                  py={2}
                  _hover={{ backgroundColor: 'gray.100' }}
                  _focus={{ backgroundColor: 'gray.100' }}>
                  <Text
                    color={active === category.slug?.toLowerCase() ? 'primary.500' : 'gray.500'}
                    fontWeight={500}
                    fontSize={16}
                    lineHeight={24}>
                    {category.name}
                  </Text>
                </Menu.Item>
              ))}
            </Menu>
          </Box>
        </HStack>

        <Center flexDirection="row">
          <Box>
            <Menu
              borderRadius={10}
              bg="background.light"
              borderWidth={0}
              w={169}
              onOpen={toggleProfileMenu}
              onClose={toggleProfileMenu}
              trigger={(triggerProps) => (
                <Pressable
                  {...triggerProps}
                  accessibilityLabel="Profile menu"
                  borderWidth={2}
                  borderColor="gray.200"
                  rounded="full">
                  <HStack alignItems="center" space={2} py={3} px={4}>
                    <Icons.Profile color="gray.700" size={4} />
                    <Text color="gray.700" fontWeight={500} fontSize={16}>
                      Meu Perfil
                    </Text>
                    <Animated.View style={{ transform: [{ rotate: profileMenuRotation }] }}>
                      <Icons.Arrow color="gray.500" direction="down" size={6} />
                    </Animated.View>
                  </HStack>
                </Pressable>
              )}>
              {navigationProfileList.map((profile) => (
                <Menu.Item
                  key={profile.slug}
                  onPress={() => linkTo(`/perfil/${profile.slug}`)}
                  alignItems="center"
                  px={0}
                  _hover={{ backgroundColor: 'gray.100' }}
                  _focus={{ backgroundColor: 'gray.100' }}>
                  <Text
                    color={active === profile.slug?.toLowerCase() ? 'primary.500' : 'gray.500'}
                    fontWeight={500}
                    fontSize={16}
                    lineHeight={24}>
                    {profile.label}
                  </Text>
                </Menu.Item>
              ))}
            </Menu>
          </Box>

          <Box ml={6} mt="1px">
            <NotificationsButton color="gray.500" />
          </Box>

          <Pressable onPress={() => linkTo('/carrinho')} position="relative">
            <Icons.Shopping color="gray.500" size={Platform.OS === 'web' ? 7 : 5} ml={6} mr={2} />
            <Center
              bg="primary.500"
              borderColor="primary.500"
              borderRadius="full"
              borderWidth={1}
              h={5}
              position="absolute"
              top="0"
              right="1px"
              w={5}>
              <Heading
                color="white"
                fontSize="12px"
                fontWeight="bold"
                lineHeight="15px"
                pl="1px"
                position="relative"
                bottom="1px">
                {checkoutAtom?.total?.items ?? 0}
              </Heading>
            </Center>
          </Pressable>
        </Center>
      </HStack>
    </Center>
  )
}
