import { FunctionComponent, useRef } from 'react'

import { ProductImage } from 'integration/resources/products'
import { AnimatedSlideDot } from 'molecules'
import { Box, Heading, Image, Modal, Text, VStack, HStack } from 'native-base'
import { useWindowDimensions } from 'react-native'
import Animated, { useAnimatedScrollHandler, useSharedValue } from 'react-native-reanimated'

export type FullScreenImagesProps = {
  description: string
  images: ProductImage[]
  isOpen: boolean
  name: string
  onClose: () => void
}

export const FullScreenImages: FunctionComponent<FullScreenImagesProps> = ({
  description,
  images,
  isOpen,
  name,
  onClose
}) => {
  const scrollX = useSharedValue(0)

  const refScrollView = useRef<Animated.ScrollView>(null)

  const { width: SCREEN_WIDTH } = useWindowDimensions()

  const scrollHandler = useAnimatedScrollHandler((event) => {
    scrollX.value = event.contentOffset.x
  })

  return (
    <Modal bg="black" p={0} isOpen={isOpen} onClose={onClose}>
      <Modal.CloseButton
        _icon={{ color: 'white', size: 6 }}
        _android={{ backgroundColor: 'none' }}
        _ios={{ backgroundColor: 'none' }}
      />

      <VStack w={`${SCREEN_WIDTH}px`} justifyContent="center">
        <Animated.ScrollView
          horizontal
          onScroll={scrollHandler}
          pagingEnabled
          ref={refScrollView}
          scrollEventThrottle={32}
          showsHorizontalScrollIndicator={false}>
          {images.map((slide: ProductImage, index: number) => (
            <Box key={`${slide}${index}`} w={`${SCREEN_WIDTH}px`}>
              <Image
                source={slide?.imageUrl ? { uri: slide.imageUrl } : require('assets/not-found.png')}
                alt={name}
                h={`${SCREEN_WIDTH * 1}px`}
                w="full"
                resizeMode="cover"
              />
            </Box>
          ))}
        </Animated.ScrollView>
      </VStack>

      <HStack
        alignItems="flex-end"
        space={1}
        px={6}
        justifyContent="center"
        w={`${SCREEN_WIDTH}px`}
        position="absolute"
        bottom={113}>
        {images.map((slide: ProductImage, index: number) => (
          <AnimatedSlideDot
            index={index}
            key={`${slide}${index}`}
            scrollX={scrollX}
            slideWidth={SCREEN_WIDTH}
          />
        ))}
      </HStack>

      <Modal.Footer
        alignSelf="flex-end"
        bg="transparent"
        bottom={0}
        px={6}
        pt={8}
        pb={2}
        position="absolute"
        left={0}
        justifyContent="flex-start"
        borderTopWidth={0}>
        <VStack w="full">
          <Heading color="white" fontSize="18px" mb={4} style={{ fontWeight: '600' }}>
            {name}
          </Heading>

          <Text color="white" fontSize="14px">
            {description}
          </Text>
        </VStack>
      </Modal.Footer>
    </Modal>
  )
}
