import { FunctionComponent } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Icons } from 'atoms'
import {
  Address,
  useDeleteAddressMutation,
  useSaveAddressMutation
} from 'integration/resources/address'
import { HStack, Box, VStack, Text, Button, Heading, useBreakpointValue } from 'native-base'
import { MaskService } from 'react-native-masked-text'
import { useQueryClient } from 'react-query'

type Props = {
  address: Address
  isCheckoutFlow: boolean
  isMobile?: boolean
}

export const AddressCard: FunctionComponent<Props> = ({ address, isCheckoutFlow }) => {
  const linkTo = useLinkTo()

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const queryClient = useQueryClient()

  const deleteAddressMutation = useDeleteAddressMutation()

  const updateAddressMutation = useSaveAddressMutation()

  const handleDeleteAddress = (address: Address) => {
    deleteAddressMutation.mutate(address, {
      onSuccess: () => {
        queryClient.invalidateQueries('getAddress')
      }
    })
  }

  const handleUpdateToMainAddress = (address: Address) => {
    updateAddressMutation.mutate(
      { ...address, main: true },
      {
        onSuccess: () => {
          linkTo('/carrinho')
        }
      }
    )
  }

  const renderAddresses = () =>
    isMobile ? (
      <VStack>
        <HStack space="xs">
          <VStack space="xs" flexWrap="wrap" maxW="98%">
            <HStack maxW="90%">
              <Heading
                w="full"
                color="gray.500"
                ml={7}
                fontSize="12px"
                lineHeight="18px"
                fontWeight="bold"
                textAlign="right">
                {address.main ? 'Endereço principal' : ''}
              </Heading>
            </HStack>

            <HStack>
              <Icons.Pin color="primary.500" size={6} mr={2} />
              <Text color="primary.500" fontSize="16px" lineHeight="24px" maxW="90%">
                {address.street}, {address.number},{' '}
                {address.complement ? `${address.complement}, ` : ''}
                {address.district}
              </Text>
            </HStack>
            <Text ml={7} color="gray.500" fontSize="16px" lineHeight="24px" width="90%">
              CEP {address.postal_code} - {address.city} - {address.state}
            </Text>

            <Text ml={7} mb={2} color="gray.500" fontSize="16px" lineHeight="24px" width="100%">
              {address.phone_number
                ? `Telefone ${MaskService.toMask('cel-phone', address.phone_number ?? '', {
                    maskType: 'BRL',
                    withDDD: true
                  })}`
                : ''}
            </Text>
          </VStack>
        </HStack>

        <HStack space={2} pt={2} direction="column" display="flex">
          <Button
            onPress={() => linkTo(`/endereco/salvar/${address.id}`)}
            size="sm"
            flex={1}
            variant="outline"
            _text={{
              fontSize: '16px',
              fontWeight: 'semibold',
              lineHeight: '21px'
            }}>
            Editar endereço
          </Button>
          {!address.main && (
            <Button
              onPress={() => handleDeleteAddress(address)}
              isLoading={deleteAddressMutation.isLoading}
              size="sm"
              flex={1}
              variant="unstyled"
              _text={{
                color: 'gray.600',
                fontSize: '16px',
                fontWeight: 'semibold',
                lineHeight: '21px'
              }}
              startIcon={<Icons.Trash h={4} w={4} color="gray.600" />}>
              Excluir endereço
            </Button>
          )}
        </HStack>
      </VStack>
    ) : (
      <VStack space={8} w="full" h="full" justifyContent="space-between">
        <HStack justifyItems="flex-end">
          <Heading
            w="full"
            color="gray.500"
            fontSize="12px"
            lineHeight="18px"
            fontWeight="bold"
            textAlign="end">
            {address.main ? 'Endereço principal' : ''}
          </Heading>
        </HStack>
        <HStack space="xs">
          <VStack space="xs" flexWrap="wrap" maxW="100%">
            <HStack>
              <Icons.Pin color="primary.500" size={6} mr={2} />
              <Text color="primary.500" fontSize="16px" lineHeight="24px" maxW="85%">
                {address.street}, {address.number},{' '}
                {address.complement ? `${address.complement}, ` : ''}
                {address.district}
              </Text>
            </HStack>
            <Text ml={7} color="gray.500" fontSize="16px" lineHeight="24px" width="100%">
              CEP {address.postal_code} - {address.city} - {address.state}
            </Text>

            <Text ml={7} color="gray.500" fontSize="16px" lineHeight="24px" width="100%">
              {address.phone_number
                ? `Telefone ${MaskService.toMask('cel-phone', address.phone_number ?? '', {
                    maskType: 'BRL',
                    withDDD: true
                  })}`
                : ''}
            </Text>
          </VStack>
        </HStack>

        <HStack space={4} alignItems="center">
          <Button
            onPress={() => linkTo(`/endereco/salvar/${address.id}`)}
            size="sm"
            flex={1}
            variant="outline"
            _text={{
              fontSize: '16px',
              fontWeight: 'semibold',
              lineHeight: '21px'
            }}>
            Editar endereço
          </Button>
          {!address.main && (
            <Button
              onPress={() => handleDeleteAddress(address)}
              isLoading={deleteAddressMutation.isLoading}
              size="sm"
              flex={1}
              variant="ghost"
              _text={{
                fontSize: '16px',
                fontWeight: 'semibold',
                lineHeight: '21px',
                color: 'gray.600'
              }}
              startIcon={<Icons.Trash h={4} w={4} color="gray.600" />}>
              Excluir endereço
            </Button>
          )}
        </HStack>
      </VStack>
    )

  return (
    <Box
      bg="white"
      borderRadius="10px"
      w={isMobile ? 'full' : '48%'}
      borderWidth={isCheckoutFlow ? (address.main ? 1 : 0) : 0}
      borderColor={isCheckoutFlow && address.main ? 'primary.500' : undefined}
      p={4}
      style={{
        shadowColor: 'rgba(0, 0, 0, 0.04)',
        shadowOffset: {
          width: 0,
          height: 3
        },
        shadowOpacity: 1,
        shadowRadius: 16,
        elevation: 7
      }}>
      {renderAddresses()}
      {isCheckoutFlow && (
        <Box mt={4}>
          <VStack space={4}>
            {!address.main && (
              <Button
                onPress={() => handleUpdateToMainAddress(address)}
                isLoading={updateAddressMutation.isLoading}
                size="sm"
                _text={{
                  fontSize: '16px',
                  fontWeight: 'semibold',
                  lineHeight: '21px'
                }}>
                Tornar como principal
              </Button>
            )}
          </VStack>
        </Box>
      )}
    </Box>
  )
}
