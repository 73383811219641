import { FunctionComponent } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Icons } from 'atoms'
import { Button, Center, Divider, VStack } from 'native-base'

export const navigationProfileList = [
  {
    slug: 'dados-pessoais',
    label: 'Dados pessoais',
    icon: 'Profile'
  },
  {
    slug: 'meus-pedidos',
    label: 'Meus pedidos',
    icon: 'Receipt'
  },
  {
    slug: 'meus-enderecos',
    label: 'Meus endereços',
    icon: 'Truck'
  },
  // disabled temporarily
  // {
  //   slug: 'minhas-medidas',
  //   label: 'Minhas medidas',
  //   icon: 'Clothing'
  // },
  {
    slug: 'favoritos',
    label: 'Favoritos',
    icon: 'Heart'
  }
]

type NavigationProfileProps = {
  activeStep: string
}

export const NavigationProfile: FunctionComponent<NavigationProfileProps> = ({ activeStep }) => {
  const linkTo = useLinkTo()

  return (
    <VStack maxW={289}>
      {navigationProfileList.map((list, index) => {
        const IconComponent = list.icon ? Icons[list.icon as keyof typeof Icons] : undefined

        return (
          <VStack key={list.slug}>
            <Button
              onPress={() => linkTo(`/perfil/${list.slug}`)}
              variant="ghost"
              w={289}
              px={4}
              py={6}
              h={88}
              textAlign="initial"
              justifyContent="flex-start"
              borderRadius={16}
              _text={{
                fontWeight: 600,
                fontSize: 20,
                lineHeight: 30,
                color: 'gray.600'
              }}
              bg={activeStep === list.slug ? 'gray.100' : 'tranparent'}
              _hover={{ bg: 'gray.100', _text: { color: 'gray.700', fontWeight: 700 } }}
              _pressed={{ bg: 'gray.100', _text: { color: 'gray.700', fontWeight: 700 } }}
              _stack={{
                space: 4
              }}
              startIcon={
                IconComponent ? (
                  <Center bg="gray.100" borderRadius={50} h={10} w={10}>
                    {/* @ts-ignore */}
                    <IconComponent size="sm" color="primary.500" />
                  </Center>
                ) : undefined
              }>
              {list.label}
            </Button>

            {index !== navigationProfileList.length && <Divider bg="gray.100" />}
          </VStack>
        )
      })}
    </VStack>
  )
}
