import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'

import { ChevronIcon } from 'atoms/Icons/ChevronIcon'
import { ProductImage } from 'integration/resources/products'
import {
  Box,
  Center,
  Heading,
  HStack,
  Image,
  Modal,
  Pressable,
  Text,
  View,
  VStack
} from 'native-base'
import Animated, { useAnimatedScrollHandler, useSharedValue } from 'react-native-reanimated'

export type ProductGalleryDesktopProps = {
  images: ProductImage[]
  name: string
  description: string
  mainImage: ProductImage
}

export const ProductGalleryDesktop: FunctionComponent<ProductGalleryDesktopProps> = ({
  images,
  name,
  description,
  mainImage
}) => {
  const refScrollView = useRef<Animated.ScrollView>(null)

  const [centerImage, setCenterImage] = useState<ProductImage & { index: number }>({
    ...mainImage,
    index: 0
  })

  const [showModal, setShowModal] = useState(false)

  const SLIDE_WIDTH = 840

  const scrollX = useSharedValue(0)

  const scrollHandler = useAnimatedScrollHandler((event) => {
    scrollX.value = event.contentOffset.x
  })

  const moveSlide = useCallback(
    (direction: 'left' | 'right') => {
      let nextX, x

      if (direction === 'left') {
        nextX = scrollX.value - SLIDE_WIDTH

        x = nextX < 0 ? (images.length - 1) * SLIDE_WIDTH : nextX
      } else {
        nextX = scrollX.value + SLIDE_WIDTH

        x = nextX > (images.length - 1) * SLIDE_WIDTH ? 0 : nextX
      }

      refScrollView.current?.scrollTo({ animated: true, x })

      const index = Math.round(nextX / SLIDE_WIDTH)

      setCenterImage({ ...images[index], index })
    },
    [SLIDE_WIDTH, scrollX.value, images]
  )

  const openModal = () => {
    setTimeout(() => {
      refScrollView.current?.scrollTo({
        animated: false,
        x: centerImage.index * SLIDE_WIDTH
      })
    }, 0)

    setShowModal(true)
  }

  const counter = `${centerImage.index > images.length - 1 ? 1 : centerImage.index + 1}/${
    images.length
  }`

  const isEnableCarousel = images.length > 3

  useEffect(() => {
    setCenterImage({
      ...mainImage,
      index: 0
    })
  }, [mainImage])

  return (
    <VStack width="630px" mr="18px" mt={10}>
      <Pressable onPress={openModal}>
        <HStack w="full" h={120} borderRadius="10">
          <Image
            source={
              centerImage?.imageUrl
                ? { uri: centerImage.imageUrl }
                : require('assets/not-found.png')
            }
            alt="img 1"
            w="full"
            resizeMode="contain"
          />
        </HStack>
      </Pressable>
      <HStack w="full" h={37} mt={4} alignItems="center" justifyContent="space-between">
        <Pressable
          onPress={() => {
            refScrollView.current?.scrollTo({
              animated: true,
              x: -200
            })
          }}>
          <Center
            w={12}
            h={12}
            bg="white"
            borderRadius={48}
            // @ts-ignore
            cursor={isEnableCarousel ? 'pointer' : 'not-allowed'}
            style={{
              shadowColor: 'black',
              shadowOffset: {
                width: 0,
                height: 1
              },
              shadowOpacity: 0.05,
              shadowRadius: 5.88,
              elevation: 4
            }}>
            <ChevronIcon
              color={isEnableCarousel ? 'red.500' : 'gray.400'}
              direction="left"
              style={{ width: 20, height: 20 }}
            />
          </Center>
        </Pressable>

        <HStack w={130}>
          <Animated.ScrollView
            horizontal
            showsHorizontalScrollIndicator={false}
            ref={refScrollView}>
            <HStack space={5}>
              {images.map((image: ProductImage, index: number) => (
                <Pressable
                  key={`image-${index}`}
                  onPress={() => setCenterImage({ ...image, index })}>
                  <Image
                    source={
                      image?.imageUrl ? { uri: image.imageUrl } : require('assets/not-found.png')
                    }
                    alt={image.imageDescription}
                    w={37}
                    h={37}
                    resizeMode="cover"
                    borderRadius="10px"
                  />
                </Pressable>
              ))}
            </HStack>
          </Animated.ScrollView>
        </HStack>

        <Pressable
          onPress={() => {
            refScrollView.current?.scrollTo({
              animated: true,
              x: 200
            })
          }}>
          <Center
            w={12}
            h={12}
            bg="white"
            borderRadius={48}
            // @ts-ignore
            cursor={isEnableCarousel ? 'pointer' : 'not-allowed'}
            style={{
              shadowColor: 'black',
              shadowOffset: {
                width: 0,
                height: 1
              },
              shadowOpacity: 0.05,
              shadowRadius: 5.88,
              elevation: 4
            }}>
            <ChevronIcon
              color={isEnableCarousel ? 'red.500' : 'gray.400'}
              direction="right"
              style={{ width: 20, height: 20 }}
            />
          </Center>
        </Pressable>
      </HStack>

      <Modal isOpen={showModal} bg="rgba(18, 19, 22, 0.7)" onClose={() => setShowModal(false)}>
        <Box borderRadius="12px" position="absolute" top="80px">
          <Modal.CloseButton
            _icon={{
              color: 'white',
              size: 5,
              _web: { color: 'gray.700' }
            }}
            _web={{
              backgroundColor: 'white',
              borderRadius: '50%',
              right: '-20px',
              top: '-20px',
              size: '48px',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            position="absolute"
            right="-85px"
            top="-85px"
            style={{
              shadowColor: 'rgba(0, 0, 0, 0.2)',
              shadowOffset: {
                width: 0,
                height: 3
              },
              shadowOpacity: 0.29,
              shadowRadius: 4.65,
              elevation: 7
            }}
          />
          <HStack
            w="full"
            position="absolute"
            zIndex={1}
            top="300px"
            justifyContent="space-between">
            <Pressable onPress={() => moveSlide('left')}>
              <Box
                size="48px"
                bgColor="white"
                borderRadius="50%"
                alignItems="center"
                justifyContent="center"
                position="relative"
                right="100px">
                <ChevronIcon direction="left" color="red.500" style={{ width: 20, height: 20 }} />
              </Box>
            </Pressable>
            <Pressable onPress={() => moveSlide('right')}>
              <Box
                size="48px"
                bgColor="white"
                borderRadius="50%"
                alignItems="center"
                justifyContent="center"
                position="relative"
                left="100px">
                <ChevronIcon direction="right" color="red.500" style={{ width: 20, height: 20 }} />
              </Box>
            </Pressable>
          </HStack>
          <Box w={SLIDE_WIDTH} bgColor="white" borderRadius="12px" overflow="hidden">
            <Animated.ScrollView
              horizontal
              pagingEnabled
              onScroll={scrollHandler}
              ref={refScrollView}
              scrollEventThrottle={32}
              showsHorizontalScrollIndicator={false}>
              {images.map((item, index) => (
                <Box
                  key={`image-full-size-${index}`}
                  position="relative"
                  w="full"
                  borderRadius="12px">
                  <Image
                    alt={`${item.imageDescription}`}
                    borderWidth={{ base: 2, lg: 0 }}
                    source={{ uri: item.imageUrl }}
                    resizeMode="contain"
                    bg="white"
                    borderRadius="12px"
                    h={580}
                    w={SLIDE_WIDTH}
                  />
                </Box>
              ))}
            </Animated.ScrollView>
          </Box>
          <HStack justifyContent="center" mt={14}>
            <Text color="white">{counter}</Text>
          </HStack>
          <View
            bg="transparent"
            px={6}
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
            mt={8}
            w="full">
            <Heading
              color="white"
              fontSize="20px"
              mb={4}
              style={{ fontWeight: '600' }}
              lineHeight="31px">
              {name}
            </Heading>
            <Text color="white" fontSize="14px">
              {description}
            </Text>
          </View>
        </Box>
      </Modal>
    </VStack>
  )
}
