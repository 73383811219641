import { Icons } from 'atoms'
import { ProductImage } from 'integration/resources/products'
import {
  Button,
  Container,
  Heading,
  HStack,
  ScrollView,
  StatusBar,
  Text,
  theme,
  useBreakpointValue,
  View,
  VStack
} from 'native-base'
import {
  FooterDesktop,
  HeaderDesktop,
  ProductGalleryMobile,
  ProductGalleryDesktop,
  ProductSelectEdit
} from 'organisms'
import { Dimensions } from 'react-native'
import { RootStackScreenComponent } from 'src/navigation'

import { useProductEditScreen } from './useProductEditScreen'

const { width: SCREEN_WIDTH } = Dimensions.get('window')

export const RIGHT_BANNER_WIDTH = 493

export const SLIDE_WIDTH =
  SCREEN_WIDTH - (SCREEN_WIDTH >= theme.sizes.container.lg ? RIGHT_BANNER_WIDTH : 0)

export const ProductEditScreen: RootStackScreenComponent<'ProductEdit'> = ({
  navigation,
  route
}) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const { params } = route

  const { product } = useProductEditScreen(params?.idOrSlug ?? '')

  if (!product) {
    return <View />
  }

  const renderProductSelect = () => {
    const [mainImage] =
      product.images.filter((image: ProductImage) => image.main).length > 0
        ? product.images.filter((image: ProductImage) => image.main)
        : product.images

    return (
      <ProductSelectEdit
        productCartId={params?.productCartId}
        productSlug={product.slug}
        productId={product.id}
        name={product.name}
        description={product.description}
        price={product.price}
        sizeTableImage={product.sizeTableImage}
        colorsWithSizes={product.colorsWithSizes}
        productImage={mainImage}
      />
    )
  }

  return (
    <ScrollView>
      <HeaderDesktop />

      {isDesktop ? (
        <VStack alignItems="center" flex={1} mb={30}>
          <Container>
            <Heading mt="95px">Detalhes do produto</Heading>

            <HStack>
              <ProductGalleryDesktop
                images={product.images}
                name={product.name}
                description={product.description}
                mainImage={{
                  imageUrl: '',
                  imageDescription: '',
                  main: false,
                  color: ''
                }}
              />

              <VStack width="415px" mt={10}>
                {renderProductSelect()}
              </VStack>
            </HStack>

            <Heading fontSize={24} mt={15} style={{ fontWeight: '700' }}>
              Descrição do produto
            </Heading>

            <Text fontSize={18} mt={4} color="gray.700">
              {product.description}
            </Text>
          </Container>
        </VStack>
      ) : (
        <>
          <StatusBar animated barStyle="light-content" />

          <Button
            variant="ghost"
            position="absolute"
            right={6}
            top={6}
            zIndex={1}
            size={8}
            startIcon={<Icons.Close size={6} color="black" />}
            onPress={navigation.goBack}
          />

          <ProductGalleryMobile
            images={product.images}
            name={product.name}
            description={product.description}
          />

          {renderProductSelect()}
        </>
      )}
      <FooterDesktop />
    </ScrollView>
  )
}
