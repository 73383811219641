import { FunctionComponent } from 'react'

import { Illustrations } from 'atoms'
import { Box, Heading, VStack } from 'native-base'

export const OrdersEmpty: FunctionComponent = () => {
  return (
    <VStack justifyContent={{ base: 'center', lg: '' }} margin="auto" bg="white" space={6}>
      <Box>
        <Illustrations.EmptyFolder />
      </Box>

      <Heading
        fontSize={{ base: '16px', lg: '20px' }}
        lineHeight={{ base: '24px', lg: '30px' }}
        fontWeight={{ base: 'normal', lg: 'semibold' }}
        color="gray.500"
        margin="auto"
        mb={{ base: '32px', lg: '' }}>
        Você não tem pedidos.
      </Heading>
    </VStack>
  )
}
