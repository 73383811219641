import { FunctionComponent, useEffect, useState } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Icons } from 'atoms'
import { useBottomSheetContext } from 'contexts/BottomSheetContext'
import { ColorsWithSizes, ProductImage, ProductSize } from 'integration/resources/products'
import { ColorRadio, ProductAddRem, SizeRadio } from 'molecules'
import {
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Modal,
  Text,
  useBreakpointValue,
  View,
  VStack
} from 'native-base'
import { Dimensions, Platform } from 'react-native'
import ImageZoom from 'react-native-image-pan-zoom'
import { useCheckoutAtom } from 'src/store/checkout'

export type ProductSelectEditProps = {
  productCartId: string | undefined
  productSlug: string
  productId: string
  name: string
  description: string
  price: number
  sizeTableImage: string
  colorsWithSizes: ColorsWithSizes[]
  productImage: ProductImage
}

export const ProductSelectEdit: FunctionComponent<ProductSelectEditProps> = ({
  productCartId,
  productSlug,
  productId,
  name,
  description,
  price,
  sizeTableImage,
  colorsWithSizes,
  productImage
}) => {
  const linkTo = useLinkTo()

  const [checkoutAtom, setCheckoutAtom] = useCheckoutAtom()

  const currentCheckout = typeof checkoutAtom === 'string' ? JSON.parse(checkoutAtom) : checkoutAtom

  const [currentProduct] =
    currentCheckout?.products?.filter(
      (product: { productCartId: string }) => product.productCartId === productCartId
    ) ?? []

  const [showModal, setShowModal] = useState(false)

  const [colorWithSizes, setColorWithSizes] = useState<ColorsWithSizes | undefined>()

  useEffect(() => {
    const [current] = colorsWithSizes.filter(
      (colorWithSize) => colorWithSize.colorId === currentProduct?.color?.colorId
    )

    setColorWithSizes(current)
  }, [colorsWithSizes, currentProduct])

  useEffect(() => {
    if (currentProduct?.color && currentProduct?.color?.colorName !== colorWithSizes?.colorName) {
      setSize(currentProduct?.size)

      setAmount(currentProduct?.quantity)
    }
  }, [
    currentProduct?.color,
    colorWithSizes?.colorName,
    currentProduct?.size,
    currentProduct?.quantity
  ])

  const [size, setSize] = useState<ProductSize | undefined>(currentProduct?.size)

  const [amount, setAmount] = useState(currentProduct?.quantity ?? 1)

  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const bottomSheetContext = useBottomSheetContext()

  const handleChangeColor = (_colorWithSizes: ColorsWithSizes) => {
    setColorWithSizes(_colorWithSizes)

    setSize(undefined)

    setAmount(1)
  }

  const renderQuantityError = (limitQuantity: number) =>
    bottomSheetContext.open({
      description: `Há somente ${limitQuantity} ${
        limitQuantity === 1 ? 'disponível' : 'disponíveis'
      } para o tamanho escolhido.`,
      title: 'Erro ao selecionar o produto!'
    })

  const handleRemoveQuantity = (amount: number, quantity: number) => {
    const newAmount = Number(amount) - 1

    if (newAmount > quantity) {
      renderQuantityError(quantity)
    }

    setAmount(amount <= 0 ? 0 : newAmount)

    if (amount === 0) {
      const currentProducts = currentCheckout?.products?.filter(
        (product: { productId: string }) => product.productId !== currentProduct?.productId
      )

      const products = [...currentProducts]

      const totalItems = products.reduce(
        (previousValue, currentValue) => previousValue + currentValue.quantity,
        0
      )

      const totalPoints = products.reduce(
        (previousValue, currentValue) => previousValue + currentValue.price * currentValue.quantity,
        0
      )

      const total = {
        items: totalItems,
        points: totalPoints
      }

      setCheckoutAtom({ products, total })

      linkTo('/carrinho')
    }
  }

  const handleChangeQuantity = (amount: string, quantity: number) => {
    if (Number(amount) > quantity) {
      renderQuantityError(quantity)
    }

    setAmount(Number(amount))
  }

  const handleAddQuantity = (amount: number, limitQuantity: number) => {
    const newAmount = Number(amount) + 1

    if (newAmount > limitQuantity) {
      renderQuantityError(limitQuantity)
    }

    setAmount(newAmount)
  }

  useEffect(() => {
    if (Platform.OS === 'web') {
      const script = document.createElement('script')

      script.src = 'https://static.sizebay.technology/4622/prescript.js'

      script.defer = true

      script.id = 'sizebay-vfr-v3'

      const link = document.createElement('link')

      link.rel = 'canonical'

      link.href = `/${productSlug}`

      document.head.appendChild(script)

      document.head.appendChild(link)

      return () => {
        document.head.removeChild(script)

        document.head.removeChild(link)
      }
    }
  }, [productSlug])

  return (
    <>
      <VStack
        bg="white"
        borderTopLeftRadius={isDesktop ? '10px' : '12px'}
        borderTopRightRadius={isDesktop ? '10px' : '12px'}
        borderBottomRadius={isDesktop ? '10px' : 0}
        mt={isDesktop ? 0 : '-10px'}
        style={
          isDesktop
            ? {
                shadowColor: 'black',
                shadowOffset: {
                  width: 0,
                  height: 2
                },
                shadowOpacity: 0.05,
                shadowRadius: 8.88,
                elevation: 20
              }
            : {
                shadowColor: 'black',
                shadowOffset: {
                  width: 0,
                  height: 1
                },
                shadowOpacity: 0.39,
                shadowRadius: 8.3,
                elevation: 13
              }
        }>
        <VStack p={6} pt={5}>
          {!isDesktop && (
            <Box
              alignSelf="center"
              width={12}
              height={1}
              bg="gray.250"
              borderRadius="6"
              mb="10px"
            />
          )}

          <Heading
            color={{ base: 'gray.800', lg: 'gray.900' }}
            fontSize={{ base: '17.8px', lg: 24 }}
            lineHeight={{ base: 20, lg: 28 }}
            mt={{ base: 6, lg: 0 }}
            mb={4}
            style={{ fontWeight: '700' }}>
            {name}
          </Heading>

          {!isDesktop && (
            <Text color="gray.900" fontSize="13.84px" lineHeight="18.11px">
              {description}
            </Text>
          )}

          <Heading
            fontSize={{ base: '17.8px', lg: 24 }}
            lineHeight={{ base: 20, lg: 28 }}
            mt={6}
            mb={4}
            style={{ fontWeight: '700' }}>
            Valor
          </Heading>

          <Text
            color="gray.900"
            fontSize={{ base: '13.84px', lg: 18 }}
            lineHeight={{ base: '18.11px', lg: 32 }}>
            {price} pontos
          </Text>

          <Heading
            fontSize={{ base: '17.8px', lg: 24 }}
            lineHeight={{ base: 20, lg: 28 }}
            mt={6}
            mb={4}
            style={{ fontWeight: '700' }}>
            Cor
          </Heading>

          <HStack flexWrap="wrap">
            {colorsWithSizes.map((_colorWithSizes: ColorsWithSizes, index: number) => (
              <ColorRadio
                key={`color-${index}`}
                active={colorWithSizes?.colorHex === _colorWithSizes.colorHex}
                color={_colorWithSizes.colorHex}
                onPress={() => handleChangeColor(_colorWithSizes)}
              />
            ))}
          </HStack>

          {colorWithSizes && (
            <>
              <Heading
                fontSize={{ base: '17.8px', lg: 24 }}
                lineHeight={{ base: 20, lg: 28 }}
                mt={2}
                mb={4}
                style={{ fontWeight: '700' }}>
                Tamanho
              </Heading>

              <HStack flexWrap="wrap">
                {colorWithSizes?.sizes
                  .filter((_size) => _size.available)
                  .map((_size: ProductSize, index: number) => (
                    <SizeRadio
                      key={`size-${index}`}
                      active={size?.size === _size.size}
                      onPress={() => setSize(_size)}
                      size={_size.size}
                      amount={_size.amount}
                    />
                  ))}
              </HStack>
            </>
          )}

          {Platform.OS === 'web' ? (
            <div id="sizebay-container" style={{ flex: 1 }} />
          ) : (
            <Button
              mt={4}
              size="sm"
              w={46}
              variant="outline"
              onPress={() => setShowModal(true)}
              leftIcon={<Icons.Clothing color="red.500" size="5" />}>
              <Text
                color="red.500"
                fontFamily="heading"
                fontSize="16px"
                lineHeight="20px"
                style={{ fontWeight: '600' }}>
                Tabela de medidas
              </Text>
            </Button>
          )}
        </VStack>

        <ProductAddRem
          productSlug={productSlug}
          productId={productId}
          productCartId={productCartId}
          productName={name}
          productDescription={description}
          color={colorWithSizes}
          size={size}
          amount={amount}
          limitQuantity={size?.amount ?? 0}
          onAdd={() => handleAddQuantity(amount, size?.amount ?? 0)}
          onChangeText={(amount: string) => handleChangeQuantity(amount, size?.amount ?? 0)}
          onRemove={() => handleRemoveQuantity(amount, size?.amount ?? 0)}
          price={price}
          productImage={productImage}
        />
      </VStack>

      <Modal bg="rgba(18, 19, 22, 0.7)" isOpen={showModal} onClose={() => setShowModal(false)}>
        {isDesktop ? (
          <Box>
            <Modal.CloseButton
              _icon={{
                color: 'white',
                size: 5,
                _web: { color: 'gray.700' }
              }}
              _web={{
                backgroundColor: 'white',
                borderRadius: '50%',
                right: '-20px',
                top: '-20px',
                size: '48px',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              position="absolute"
              right="-85px"
              top="-85px"
              style={{
                shadowColor: 'rgba(0, 0, 0, 0.2)',
                shadowOffset: {
                  width: 0,
                  height: 3
                },
                shadowOpacity: 0.29,
                shadowRadius: 4.65,
                elevation: 7
              }}
            />

            <View p={6} bg="white" borderRadius="12px" width={600}>
              <Image
                source={{ uri: sizeTableImage }}
                alt="Tabela de medidas"
                resizeMode="contain"
                h={{ base: '180', lg: '500' }}
              />
            </View>
          </Box>
        ) : (
          <>
            <Modal.CloseButton
              _icon={{ color: 'white', size: 6 }}
              _web={{ backgroundColor: 'none' }}
              _android={{ backgroundColor: 'none' }}
              _ios={{ backgroundColor: 'none' }}
            />

            {/* @ts-ignore */}
            <ImageZoom
              cropWidth={Dimensions.get('window').width}
              cropHeight={Dimensions.get('window').height}
              imageWidth={300}
              imageHeight={200}>
              <Modal.Body bg="white" borderRadius="12px">
                <Image
                  source={{ uri: sizeTableImage }}
                  alt="Tabela de medidas"
                  resizeMode="contain"
                  h={45}
                />
              </Modal.Body>
            </ImageZoom>
          </>
        )}
      </Modal>
    </>
  )
}
