import {
  useDeleteFavoriteProductMutation,
  useSaveFavoriteProductMutation
} from 'integration/resources/products'
import { useQueryClient } from 'react-query'

export function useFavoriteHandler() {
  const saveFavoriteMutation = useSaveFavoriteProductMutation()

  const deleteFavoriteMutation = useDeleteFavoriteProductMutation()

  const queryClient = useQueryClient()

  const handleFavorite = (slug: string, isFavorite: boolean, product_id: string) => {
    if (isFavorite) {
      return deleteFavoriteMutation.mutate(
        { product_id },
        {
          onSuccess: () => {
            // Invalidar as queries após deletar o favorito
            queryClient.invalidateQueries('getFavoriteProducts')

            queryClient.invalidateQueries('getAllProducts')

            queryClient.invalidateQueries(['getProduct', slug])
          }
        }
      )
    }

    const payloadFavorite = { product_id }

    return saveFavoriteMutation.mutate(payloadFavorite, {
      onSuccess: () => {
        // Invalidar as queries após salvar o favorito
        queryClient.invalidateQueries('getFavoriteProducts')

        queryClient.invalidateQueries('getAllProducts')
      }
    })
  }

  return { handleFavorite }
}
