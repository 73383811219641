import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { Icons } from 'atoms'
import { TabBarLabel } from 'molecules'
import { useBreakpointValue, useTheme } from 'native-base'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useQueryClient } from 'react-query'
import { HomeScreen, ProductsScreen, ProfileScreen, OrdersScreen } from 'src/screens'

import { RootStackScreenComponent, TabParamList } from './types'

const Tab = createBottomTabNavigator<TabParamList>()

export const TabNavigator: RootStackScreenComponent<'Tab'> = () => {
  const tabBar = useBreakpointValue({ base: undefined, lg: () => null })

  const insets = useSafeAreaInsets()

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const theme = useTheme()

  const queryClient = useQueryClient()

  const listeners = {
    tabPress: () => {
      queryClient.invalidateQueries('getNotificationsCount')
    }
  }

  return (
    <Tab.Navigator
      screenOptions={{
        headerShown: false,
        tabBarActiveTintColor: theme.colors.primary[500],
        tabBarInactiveTintColor: theme.colors.gray[700],
        tabBarItemStyle: {
          paddingVertical: 8,
          flexDirection: 'column'
        },
        tabBarStyle: {
          borderTopWidth: 0.5,
          borderTopColor: theme.colors.gray[200],
          height: 62 + insets.bottom
        },
        tabBarIconStyle: {
          height: 25
        }
      }}
      sceneContainerStyle={{ backgroundColor: theme.colors.white }}
      tabBar={tabBar}>
      <Tab.Screen
        component={HomeScreen}
        name="Home"
        options={{
          tabBarIcon: ({ color }) => <Icons.Store color={color} size={5} />,
          tabBarLabel: ({ color, focused }) => (
            <TabBarLabel {...{ color, focused }}>Início</TabBarLabel>
          )
        }}
        listeners={listeners}
      />
      <Tab.Screen
        component={ProductsScreen}
        name="Products"
        initialParams={{ categorySlug: 'todos' }}
        options={{
          tabBarIcon: ({ color }) => <Icons.Star color={color} size={5} />,
          tabBarLabel: ({ color, focused }) => (
            <TabBarLabel {...{ color, focused }}>Produtos</TabBarLabel>
          )
        }}
        listeners={listeners}
      />
      <Tab.Screen
        component={OrdersScreen}
        name="Orders"
        options={{
          tabBarIcon: ({ color }) => <Icons.Gift color={color} size={5} />,
          tabBarLabel: ({ color, focused }) => (
            <TabBarLabel {...{ color, focused }}>Pedidos</TabBarLabel>
          )
        }}
        listeners={listeners}
      />
      <Tab.Screen
        component={ProfileScreen}
        name="Profile"
        initialParams={isMobile ? { slug: 'dados-pessoais' } : { slug: 'todos' }}
        options={{
          tabBarIcon: ({ color }) => <Icons.Profile color={color} size={5} />,
          tabBarLabel: ({ color, focused }) => (
            <TabBarLabel {...{ color, focused }}>Perfil</TabBarLabel>
          )
        }}
        listeners={listeners}
      />
    </Tab.Navigator>
  )
}
