import { FunctionComponent } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Icons } from 'atoms'
import { Product } from 'integration/resources/products'
import { Box, Button, Heading, HStack, Image, Text, VStack } from 'native-base'

import { useFavoriteHandler } from './useFavoriteHandler'

type Props = {
  product: Product
}

export const ProductCardMobile: FunctionComponent<Props> = ({
  product: { image, name, description, price, slug, favorite, id }
}) => {
  const linkTo = useLinkTo()

  const { handleFavorite } = useFavoriteHandler()

  const toggleFavorite = (slug: string, isFavorite: boolean, product_id: string) => {
    handleFavorite(slug, isFavorite, product_id)
  }

  return (
    <VStack w="full" position="relative" h="auto">
      <Box
        style={{
          shadowColor: 'rgba(0, 0, 0, 0.04)',
          shadowOpacity: 1,
          shadowRadius: 16,
          shadowOffset: { width: 0, height: 3 }
        }}>
        <Image
          source={image?.imageUrl ? { uri: image.imageUrl } : require('assets/not-found.png')}
          alt={image?.imageDescription ?? name}
          borderRadius={5}
          h="116px"
          w="116px"
        />
      </Box>

      <HStack w="full" position="absolute" justifyContent="flex-end">
        <Button
          variant="ghost"
          onPress={() => toggleFavorite(slug, favorite, id)}
          backgroundColor="background.base"
          borderRadius={50}
          w={10}
          h={10}
          zIndex={1000}>
          <Icons.Heart color="primary.500" filled={favorite} size={4} />
        </Button>
      </HStack>

      <VStack flex={1} justifyContent="space-between">
        <Heading
          fontSize="20px"
          lineHeight="26px"
          fontWeight="bold"
          color="gray.700"
          mt={4}
          numberOfLines={2}
          textAlign="left">
          {name}
        </Heading>

        <Text
          fontSize="16px"
          lineHeight="24px"
          fontWeight="normal"
          color="gray.500"
          mt={2}
          mb={2}
          numberOfLines={3}
          textAlign="left">
          {description}
        </Text>

        <HStack alignItems="baseline">
          <Heading fontSize="24px" lineHeight="36px" fontWeight="semibold" color="gray.700">
            {price} Pontos
          </Heading>
        </HStack>
        <HStack space={3} mt={4} justifyContent="center" flex={1}>
          <Button
            variant="ghost"
            onPress={() => linkTo(`/produto/${slug}`)}
            borderWidth="1px"
            borderColor="gray.700"
            colorScheme="gray"
            width="100%"
            maxWidth="100%"
            borderRadius={30}
            _text={{
              color: 'gray.700',
              fontSize: '16px',
              fontWeight: 'semibold',
              lineHeight: '21px'
            }}>
            Ver detalhes
          </Button>
        </HStack>
      </VStack>
    </VStack>
  )
}
