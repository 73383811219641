import { FunctionComponent, ReactNode } from 'react'

import { Text } from 'native-base'

type TabBarLabelProps = {
  color: string
  focused: boolean
  children: ReactNode
}

export const TabBarLabel: FunctionComponent<TabBarLabelProps> = ({ children, color, focused }) => {
  return (
    <Text color={color} fontSize="12px" fontWeight={focused ? 'bold' : 'normal'} lineHeight="18px">
      {children}
    </Text>
  )
}
