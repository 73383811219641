import { FunctionComponent } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { randomUUID } from 'expo-crypto'
import {
  CartProduct,
  ProductColor,
  ProductImage,
  ProductSize
} from 'integration/resources/products'
import {
  AddIcon,
  Box,
  Button,
  Center,
  HStack,
  Input,
  MinusIcon,
  Pressable,
  Text,
  useBreakpointValue,
  View,
  VStack
} from 'native-base'
import { useCheckoutAtom } from 'src/store/checkout'
import 'react-native-get-random-values'

export type ProductAddRemProps = {
  productSlug: string
  productId: string
  productCartId?: string
  productName: string
  productDescription: string
  color: ProductColor | undefined
  size: ProductSize | undefined
  amount: number
  limitQuantity: number
  onAdd: () => void
  onChangeText: (arg: string) => void
  onRemove: () => void
  price: number
  productImage: ProductImage
}

export const ProductAddRem: FunctionComponent<ProductAddRemProps> = ({
  productSlug,
  productId,
  productCartId,
  productName,
  productDescription,
  color,
  size,
  amount,
  limitQuantity,
  onAdd,
  onChangeText,
  onRemove,
  price,
  productImage
}) => {
  const linkTo = useLinkTo()

  const [checkoutAtom, setCheckoutAtom] = useCheckoutAtom()

  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const currentCheckout = typeof checkoutAtom === 'string' ? JSON.parse(checkoutAtom) : checkoutAtom

  const [currentProduct] =
    currentCheckout?.products?.filter(
      (product: { productCartId: string }) => product.productCartId === productCartId
    ) ?? []

  const newProduct = {
    productCartId: randomUUID(),
    productSlug,
    productId,
    productName,
    productDescription,
    color,
    size,
    quantity: amount,
    limitQuantity,
    price,
    productImage
  }

  const isUpdatedProduct = JSON.stringify(currentProduct) !== JSON.stringify(newProduct)

  const handleAddProduct = (newProduct: CartProduct) => {
    let currentProducts = currentCheckout?.products?.filter(
      (product: { productCartId: string }) => product.productCartId !== productCartId
    )

    const sameProduct = currentProducts?.filter((product: any) => {
      return (
        product.productId === newProduct.productId &&
        product.color.colorId === newProduct?.color?.colorId &&
        product.size.sizeId === newProduct?.size?.sizeId
      )
    })

    if (sameProduct?.length) {
      currentProducts = currentProducts?.map((product: any) => {
        if (
          product.productId === newProduct.productId &&
          product.color.colorId === newProduct?.color?.colorId &&
          product.size.sizeId === newProduct?.size?.sizeId
        ) {
          return {
            ...product,
            quantity: product.quantity + newProduct.quantity
          }
        }

        return product
      })
    }

    const products = sameProduct?.length
      ? currentProducts
      : [{ ...newProduct }, ...(currentProducts ?? [])]

    const totalItems = products.reduce(
      (previousValue: any, currentValue: any) => previousValue + currentValue.quantity,
      0
    )

    const totalPoints = products.reduce(
      (previousValue: any, currentValue: any) =>
        previousValue + currentValue.price * currentValue.quantity,
      0
    )

    const total = {
      items: totalItems,
      points: totalPoints
    }

    setCheckoutAtom({ products, total })

    linkTo('/carrinho')
  }

  const addDisabled = !amount || amount > (size?.amount ?? 0) || !size || !isUpdatedProduct

  const totalPoints = price * amount

  return (
    <VStack
      bg="white"
      style={
        isDesktop
          ? {}
          : {
              shadowColor: 'black',
              shadowOffset: {
                width: 0,
                height: 6
              },
              shadowOpacity: 0.39,
              shadowRadius: 8.3,
              elevation: 13
            }
      }
      borderBottomRadius={isDesktop ? '10px' : 0}
      mt={{ base: 8, lg: 3 }}>
      <View p={6} flexDirection="row" justifyContent="space-between">
        <HStack
          justifyContent="space-between"
          borderRadius="50px"
          borderWidth="1px"
          borderColor="gray.200"
          bg={!size ? 'gray.200' : 'white'}
          width={{ base: 31, lg: '129px' }}
          height={{ lg: '49px' }}
          // @ts-ignore
          cursor={size ? 'pointer' : 'not-allowed'}>
          <Pressable
            onPress={amount ? onRemove : () => {}}
            borderRadius={15}
            disabled={!size}
            // @ts-ignore
            cursor={amount === 0 ? 'not-allowed' : 'pointer'}>
            <Center h={{ base: '50px', lg: '47px' }} w={10} position="relative" bottom="3px">
              <MinusIcon color={!size ? 'white' : amount ? 'red.500' : 'gray.200'} size={3} cur />
            </Center>
          </Pressable>

          <Input
            isDisabled={!size}
            keyboardType="number-pad"
            bg="white"
            borderColor="transparent"
            _disabled={{ backgroundColor: 'gray.200', borderColor: 'transparent' }}
            value={String(amount)}
            color={!size ? 'white' : 'rgb(18,19,22)'}
            textAlign="center"
            mt={{ base: '1px', lg: 0 }}
            fontFamily="Assistant_600SemiBold"
            fontSize="16px"
            fontWeight="600"
            lineHeight="21px"
            onChangeText={onChangeText}
            type="text"
            h={{ base: 10, lg: '47px' }}
            w={{ base: 10, lg: '40px' }}
            pt="14px"
            // @ts-ignore
            focusOutlineColor="transparent"
            _focus={{
              borderColor: 'transparent',
              lineHeight: '21px',
              fontWeight: '600',
              backgroundColor: 'white'
            }}
            _web={{
              borderColor: 'transparent',
              lineHeight: '18px',
              fontWeight: '600',
              width: '40px',
              _focus: {
                borderWidth: 0
              }
            }}
            _hover={{ borderColor: 'transparent', backgroundColor: 'white' }}
          />

          <Pressable
            onPress={onAdd}
            borderRadius={15}
            disabled={!size || amount > limitQuantity}
            // @ts-ignore
            cursor={!size || amount > limitQuantity ? 'not-allowed' : 'pointer'}>
            <Center h="50px" w={10}>
              <AddIcon
                color={!size ? 'white' : amount > limitQuantity ? 'gray.200' : 'red.500'}
                size={3}
              />
            </Center>
          </Pressable>
        </HStack>
        {currentProduct && amount === 0 ? (
          <Button onPress={onRemove} w={47}>
            Remover
          </Button>
        ) : (
          <Button
            bg={addDisabled ? 'gray.200' : 'primary.500'}
            disabled={addDisabled}
            w={{ base: 45, lg: 50 }}
            onPress={() => handleAddProduct(newProduct)}
            cursor={addDisabled ? 'not-allowed' : 'pointer'}
            // @ts-ignore
            _disabled={{ cursor: addDisabled ? 'not-allowed' : 'pointer' }}
            // @ts-ignore
            _web={{ cursor: addDisabled ? 'not-allowed' : 'pointer' }}>
            <Box width={37} flexDirection="row" alignItems="center" justifyContent="space-between">
              <Text
                color="white"
                fontFamily="Assistant_600SemiBold"
                fontWeight={600}
                fontSize="16px"
                lineHeight={{ base: 18, lg: 21 }}>
                {currentProduct ? 'Atualizar' : 'Adicionar'}
              </Text>
              <Text
                color="white"
                fontWeight={600}
                fontFamily="Assistant_600SemiBold"
                fontSize="16px"
                lineHeight={{ base: 18, lg: 21 }}>
                {`${totalPoints} pts`}
              </Text>
            </Box>
          </Button>
        )}
      </View>
    </VStack>
  )
}
