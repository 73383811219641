import { FunctionComponent, useCallback } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Product } from 'integration/resources/products'
import { ProductCard } from 'molecules'
import { ProductCardMobile } from 'molecules/ProductCard/ProductCardMobile'
import { Box, FlatList, Link, useBreakpointValue } from 'native-base'
import { Platform } from 'react-native'

type Props = {
  products: Product[]
  vertical?: boolean
  isFavorite?: boolean
  listHeaderComponent?: React.ComponentType<any> | React.ReactElement | null | undefined
  listFooterComponent?: React.ComponentType<any> | React.ReactElement | null | undefined
}

export const ProductList: FunctionComponent<Props> = ({
  products,
  vertical = false,
  isFavorite = false,
  listHeaderComponent,
  listFooterComponent
}) => {
  const linkTo = useLinkTo()

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const numColumns = isMobile ? 0 : 3

  const keyExtractor = useCallback((item: { id: number | string }) => `key-${item.id}`, [])

  if (isFavorite) {
    return (
      <FlatList
        w={{ lg: 'full' }}
        h={isMobile ? '' : 'full'}
        data={products}
        keyExtractor={keyExtractor}
        key={`flatlist-products-${Math.random()}`}
        renderItem={({ item }) => (
          <Box
            borderRadius={isMobile ? '16px' : '0px'}
            pt={isMobile ? 6 : 0}
            pb={isMobile ? 6 : 0}
            background="white"
            px={isMobile ? 4 : 2}
            mb={isMobile ? 4 : 8}>
            <Link onPress={() => linkTo(`/produto/${item.slug}`)}>
              {isMobile ? (
                <ProductCardMobile product={item} />
              ) : (
                <ProductCard product={item} horizontal />
              )}
            </Link>
          </Box>
        )}
        showsVerticalScrollIndicator
        ListHeaderComponent={listHeaderComponent}
        ListFooterComponent={listFooterComponent}
        numColumns={1}
      />
    )
  }

  return (
    <FlatList
      w={{ lg: 'full' }}
      h={Platform.OS === 'web' && isMobile ? 320 : 'full'}
      data={products}
      keyExtractor={keyExtractor}
      key={`flatlist-products-${Math.random()}`}
      renderItem={({ item, index }) => (
        <Box mx={index % 2 === 0 ? 5 : 0} mb={isMobile ? (vertical ? 10 : 0) : 15}>
          <Link onPress={() => linkTo(`/produto/${item.slug}`)}>
            <ProductCard product={item} />
          </Link>
        </Box>
      )}
      horizontal={vertical ? false : isMobile}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      ListHeaderComponent={listHeaderComponent}
      ListFooterComponent={listFooterComponent}
      numColumns={vertical ? 2 : numColumns}
    />
  )
}
