import { useMemo } from 'react'

import { useLinkTo, useNavigation } from '@react-navigation/native'
import { useGetAddressQuery } from 'integration/resources/address'
import { useBreakpointValue } from 'native-base'

import { UseAddressScreen } from './AddressScreen.types'

export const useAddressScreen: UseAddressScreen = ({ route }) => {
  const navigation = useNavigation()

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const linkTo = useLinkTo()

  const { data: dataAddress, isLoading } = useGetAddressQuery()

  const addresses = useMemo(() => dataAddress?.data.results ?? [], [dataAddress])

  const isCheckoutFlow = route?.path?.split('/').pop() === 'trocar-endereco'

  const goToAddressSave = () => linkTo(`/endereco/salvar/`)

  const handleGoBack = () => {
    navigation?.goBack()
  }

  const LIMIT_ADDRESS = 5

  return {
    isLoading,
    isMobile,
    handleGoBack,
    goToAddressSave,
    addresses,
    isCheckoutFlow,
    LIMIT_ADDRESS
  }
}
