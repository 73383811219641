import { FunctionComponent, useMemo } from 'react'

import { Product, useGetFavoriteProductsQuery } from 'integration/resources/products'
import { FavoriteProductsEmpty } from 'molecules'
import { Heading, useBreakpointValue, VStack } from 'native-base'
import { ProductList } from 'organisms/ProductList/ProductList'
import { ProductListPreview } from 'organisms/ProductListPreview/ProductListPreview'

export const FavoriteProducts: FunctionComponent = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { data, isLoading } = useGetFavoriteProductsQuery()

  //@ts-ignore
  const products: Product[] = useMemo(() => data?.data ?? [], [data])

  return (
    <VStack>
      <Heading
        color="gray.700"
        fontWeight={700}
        fontSize={{ base: '18px', lg: '24px' }}
        lineHeight={{ base: '23px', lg: '36px' }}
        mb={8}>
        Produtos favoritos
      </Heading>
      {isLoading && <ProductListPreview vertical={isMobile} />}
      {products.length > 0 && !isLoading ? (
        <ProductList products={products ?? []} isFavorite />
      ) : (
        <>
          <Heading color="gray.700" fontWeight={700} fontSize="24px" lineHeight="36px" mb={8}>
            Produtos favoritos
          </Heading>
          {isLoading && <ProductListPreview vertical={isMobile} />}
          {products.length > 0 && !isLoading ? (
            <ProductList products={products ?? []} isFavorite />
          ) : (
            <FavoriteProductsEmpty />
          )}
        </>
      )}
    </VStack>
  )
}
