import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQuery } from 'react-query'

import * as requests from './requests'
import { Product, ProductFavorite, ProductsData } from './types'
import { ResponseError } from '../../types'

export const useGetAllProductsQuery = () =>
  useQuery<AxiosResponse<ProductsData>, AxiosError<ResponseError>>(
    'getAllProducts',
    requests.getAllProducts
  )

export const useGetAllProductsByCategoryQuery = (category: string) =>
  useQuery<AxiosResponse<ProductsData>, AxiosError<ResponseError>>(
    ['getAllProductsByCategory', category],
    () => requests.getAllProductsByCategory(category)
  )

export const useGetFeaturedProductsQuery = () =>
  useQuery<AxiosResponse<ProductsData>, AxiosError<ResponseError>>(
    'getFeaturedProducts',
    requests.getFeaturedProducts
  )

export const useGetFavoriteProductsQuery = () =>
  useQuery<AxiosResponse<Product>, AxiosError<ResponseError>>(
    'getFavoriteProducts',
    requests.getFavoriteProducts
  )

export const useSaveFavoriteProductMutation = () => {
  return useMutation<AxiosResponse<ProductFavorite>, AxiosError<ResponseError>, ProductFavorite>(
    requests.saveFavoriteProducts
  )
}

export const useDeleteFavoriteProductMutation = () => {
  return useMutation<AxiosResponse<ProductFavorite>, AxiosError<ResponseError>, ProductFavorite>(
    requests.deleteFavoriteProducts
  )
}

export const useGetProductQuery = (idOrSlug: string) => {
  return useQuery<AxiosResponse<Product>, AxiosError<ResponseError>>(['getProduct', idOrSlug], () =>
    requests.getProduct(idOrSlug)
  )
}

export const useGetProductDetailQuery = (slug?: string) => {
  return useQuery<AxiosResponse<Product>, AxiosError<ResponseError>>(
    [slug],
    requests.getProductDetail,
    {
      enabled: false,
      refetchOnWindowFocus: false
    }
  )
}
