import { FunctionComponent } from 'react'

import { OrdersEmpty } from 'molecules'
import { Heading, ScrollView, VStack } from 'native-base'
import { BottomSheetOrderEvaluate, OrderItemPreview, OrdersEstimatedDelivery } from 'organisms'

import { ListPaginationOrders } from './ListPaginationOrders'
import { useOrders } from './useOrders'

export const Orders: FunctionComponent = () => {
  const {
    allDeliveredOrders,
    allNotDeliveredOrders,
    isLoading,
    scrollViewRef,
    currentPage,
    totalPages,
    setCurrentPage
  } = useOrders()

  return (
    <ScrollView showsVerticalScrollIndicator ref={scrollViewRef}>
      <VStack maxW="1280px" w="full">
        <Heading
          fontSize={{ base: '18px', lg: '24px' }}
          lineHeight={{ base: '24px', lg: '36px' }}
          fontWeight="bold"
          color={{ base: 'gray.700', lg: 'gray.800' }}
          mb="24px">
          Meus pedidos
        </Heading>

        {isLoading ? (
          <>
            {Array(10)
              .fill(0)
              .map((_, index) => (
                <OrderItemPreview key={index} />
              ))}
          </>
        ) : (
          <>
            {(allNotDeliveredOrders?.results ?? []).length > 0 ? (
              (allNotDeliveredOrders?.results ?? []).map((order) => (
                <OrdersEstimatedDelivery order={order} key={order.id} />
              ))
            ) : (
              <OrdersEmpty />
            )}

            {(allDeliveredOrders ?? []).length > 0 ? (
              <>
                <Heading
                  fontSize={{ base: '18px', lg: '24px' }}
                  lineHeight={{ base: '24px', lg: '36px' }}
                  fontWeight="bold"
                  color="gray.900"
                  mt="24px">
                  Histórico
                </Heading>

                <ScrollView showsVerticalScrollIndicator={false}>
                  <ListPaginationOrders
                    allDeliveredOrders={allDeliveredOrders}
                    isLoading={isLoading}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                  />
                </ScrollView>
              </>
            ) : null}
          </>
        )}
      </VStack>

      <BottomSheetOrderEvaluate />
    </ScrollView>
  )
}
