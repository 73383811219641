import { BackButton, Breadcrumb } from 'atoms'
import { Box, HStack, Heading, ScrollView, Skeleton, Text, VStack } from 'native-base'
import { FooterDesktop, HeaderDesktop } from 'organisms'

import { ListPaginationStatement } from './ListPaginationStatement'
import { ResponsiveStatementScreenComponent } from './StatementScreen.types'

export const StatementScreenDesktop: ResponsiveStatementScreenComponent = ({
  allStatements,
  profileResume,
  normalizeOperationDate,
  isLoading
}) => {
  return (
    <Box flex={1} bgColor="white">
      <HeaderDesktop active="Extrato" />
      <Box maxW="1280px" m="auto" w="full">
        <HStack w="full" mt={4} alignItems="center" justifyContent="space-between">
          <BackButton linkTo="/perfil/dados-pessoais">Voltar para Meu Perfil</BackButton>

          <Breadcrumb>
            <Breadcrumb.Link linkTo="/inicio">Home</Breadcrumb.Link>
            <Breadcrumb.Item>Extrato</Breadcrumb.Item>
          </Breadcrumb>
        </HStack>

        <Heading fontSize="32px" lineHeight="48px" mt="80px" fontWeight="bold" color="gray.800">
          Extrato
        </Heading>

        <Box width="full">
          <Box mt={10} mb={6}>
            <Text fontSize="16px" lineHeight="24px" fontWeight="bold" color="gray.500">
              Pontos disponíveis
            </Text>

            <VStack space={2} w="full">
              <Skeleton my="4" w={40} isLoaded={!isLoading} rounded="md">
                <HStack alignItems="baseline" space={1}>
                  <Heading fontSize="32px" lineHeight="48px" fontWeight="bold" color="red.500">
                    {profileResume?.balance}
                  </Heading>
                  <Heading fontSize="18px" lineHeight="20px" fontWeight="bold" color="red.500">
                    {' '}
                    PT{profileResume && profileResume?.balance > 1 ? 'S' : ''}
                  </Heading>
                </HStack>
              </Skeleton>
            </VStack>
          </Box>
          <ScrollView showsVerticalScrollIndicator={false}>
            <ListPaginationStatement
              allStatements={allStatements}
              normalizeOperationDate={normalizeOperationDate}
              isLoading={isLoading}
            />
          </ScrollView>
        </Box>
      </Box>
      <FooterDesktop />
    </Box>
  )
}
