import { lazy, Suspense } from 'react'

import { Spinner } from 'native-base'

import { AddressScreenComponent } from './AddressScreen.types'
import { useAddressScreen } from './useAddressScreen'

const AddressScreenDesktop = lazy(() => import('./AddressScreen.desktop'))

const AddressScreenMobile = lazy(() => import('./AddressScreen.mobile'))

export const AddressScreen: AddressScreenComponent = ({ navigation, route }) => {
  const addressScreen = useAddressScreen({ navigation, route })

  const Screen = addressScreen.isMobile ? AddressScreenMobile : AddressScreenDesktop

  return (
    <Suspense fallback={<Spinner />}>
      <Screen {...addressScreen} />
    </Suspense>
  )
}
