import { ButtonGradient } from 'atoms'
import { Box, Heading, Text } from 'native-base'
import { ContentFunctionComponent } from 'src/contexts/BottomSheetContext'

export type DefaultBottomSheetFeedbackProps = {
  buttonDescription?: string
  description: string
  title: string
}

export const DefaultBottomSheetFeedback: ContentFunctionComponent<
  DefaultBottomSheetFeedbackProps
> = ({ buttonDescription = 'OK', close, description, title }) => {
  return (
    <Box p={5}>
      <Heading textAlign="center">{title}</Heading>
      <Text textAlign="center">{description}</Text>
      <ButtonGradient onPress={close} testID="closeButton">
        {buttonDescription}
      </ButtonGradient>
    </Box>
  )
}
