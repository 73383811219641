import { ExpoConfig } from '@expo/config-types'

import packageManager from './package.json'

const [version, buildNumber] = packageManager.version.split('-')

const config: ExpoConfig = {
  owner: 'laboritinc',
  name: 'Lojinha Financeira',
  slug: 'lojinha-financeira',
  version,
  orientation: 'portrait',
  icon: './assets/icon.png',
  plugins: [
    [
      'expo-build-properties',
      {
        ios: {
          useFrameworks: 'static'
        }
      }
    ],
    'expo-font',
    'expo-secure-store',
    '@react-native-firebase/app'
  ],
  splash: {
    image: './assets/splash.png',
    resizeMode: 'cover'
  },
  updates: {
    fallbackToCacheTimeout: 0,
    url: 'https://u.expo.dev/05a14d20-9b57-4d4d-84eb-a379fc7c4cfd'
  },
  assetBundlePatterns: ['**/*'],
  ios: {
    supportsTablet: true,
    bundleIdentifier: 'com.webmotors.lojinhafinanceira',
    buildNumber,
    googleServicesFile: './GoogleService-Info.plist'
  },
  android: {
    adaptiveIcon: {
      foregroundImage: './assets/adaptive-icon.png',
      backgroundColor: '#FFFFFF'
    },
    package: 'com.webmotors.lojinhafinanceira',
    googleServicesFile: './google-services.json',
    versionCode: Number(buildNumber),
    intentFilters: [
      {
        action: 'VIEW',
        autoVerify: true,
        data: [
          {
            scheme: 'https',
            host: 'lojinhafinanceira.laborit.com.br'
          }
        ],
        category: ['BROWSABLE', 'DEFAULT']
      },
      {
        action: 'VIEW',
        autoVerify: true,
        data: [
          {
            scheme: 'https',
            host: 'lojinhafinanceira.com.br'
          }
        ],
        category: ['BROWSABLE', 'DEFAULT']
      },
      {
        action: 'VIEW',
        autoVerify: true,
        data: [
          {
            scheme: 'https',
            host: '*.lojinhafinanceira.com.br',
            pathPrefix: '/recuperar-senha/nova-senha'
          }
        ],
        category: ['BROWSABLE', 'DEFAULT']
      }
    ]
  },
  notification: {
    icon: './assets/favicon.png',
    color: '#FFFFFF'
  },
  web: {
    favicon: './assets/favicon.png'
  },
  extra: {
    eas: {
      projectId: '05a14d20-9b57-4d4d-84eb-a379fc7c4cfd'
    }
  },
  runtimeVersion: {
    policy: 'sdkVersion'
  },
  scheme: 'store'
}

export default config
