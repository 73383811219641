import { FunctionComponent } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Illustrations } from 'atoms'
import { Box, Button, Text, VStack } from 'native-base'

export const FavoriteProductsEmpty: FunctionComponent = () => {
  const linkTo = useLinkTo()

  return (
    <VStack margin="auto" bg="white" justifyContent="center" space={6}>
      <Box margin={{ base: '', lg: 'auto' }} alignItems="center">
        <Illustrations.EmptyFolder />
      </Box>

      <Text
        mt={{ base: '16px', lg: '' }}
        fontSize={{ base: '16px', lg: '20px' }}
        maxW={{ base: '70%', lg: '' }}
        textAlign={{ base: 'center', lg: 'initial' }}
        lineHeight={{ base: '24px', lg: '30px' }}
        color="gray.500"
        margin="auto">
        Você ainda não favoritou nenhum produto.
      </Text>

      <Button
        onPress={() => linkTo(`/produtos/todos`)}
        margin="auto"
        size="md"
        flex={1}
        variant="solid"
        maxW="194px"
        w="full"
        _text={{
          fontSize: '16px',
          fontWeight: 'semibold',
          lineHeight: '21px'
        }}>
        Ver todos os produtos
      </Button>
    </VStack>
  )
}
