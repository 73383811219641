import { typography } from '../typography'

export const FormControlLabel = {
  baseStyle: {
    _astrick: {
      color: 'error'
    },
    _text: {
      color: 'gray.500',
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '24px'
    },
    _invalid: {
      borderColor: 'primary.300',
      color: 'gray.500'
    }
  }
}

export const FormControlErrorMessage = {
  baseStyle: {
    _text: {
      color: 'error',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '18px',
      fontFamily: typography.fontConfig.Assistant[400].normal
    }
  }
}
