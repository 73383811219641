import { Product, ProductFavorite, ProductsData } from './types'
import client from '../../client'

export const getAllProducts = () => client.get<ProductsData>('v1/products/')

export const getAllProductsByCategory = (category: string) =>
  client.get<ProductsData>(`v1/products/category/${category}`)

export const getFeaturedProducts = () => client.get<ProductsData>('v1/products/featured/')

export const getFavoriteProducts = () => client.get<Product>('v1/products/favorites/')

export const saveFavoriteProducts = (data: ProductFavorite) => {
  return client.post<ProductFavorite>(`v1/product/add/favorite/${data.product_id}`, data)
}

export const deleteFavoriteProducts = (data: ProductFavorite) => {
  return client.delete<ProductFavorite>(`v1/product/delete/favorite/${data.product_id}`)
}

export const getProduct = (queryKey: any) => {
  return client.get<Product>(`v1/product/${queryKey}`)
}

export const getProductDetail = (queryKey: any) => {
  return client.get<Product>(`v1/product/${queryKey.queryKey[0]}`)
}
